import React from "react";
import "./marshalDetailsTile.scss";

class MarshalDetailsTile extends React.Component {
  render() {
    const { handleViewMarshalDetails } = this.props;

    return (
      <div
        className="marshalDetailsTile"
        onClick={() => handleViewMarshalDetails()}
      >
        <div className="center">
          <div className="marshalDetails">
            <span className="name">View Marshal Details</span>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/tile/purpleangleright.svg"
              alt="dropDown"
              className="dropDownIcon"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MarshalDetailsTile;
