import React, { Component } from "react";
import "./popUpModal.scss";

class PopUpModal extends Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { children, isOpen, title, width } = this.props;
    if (!isOpen) return null;

    return (
      <div className="modalOverlay" onClick={() => this.handleClose()}>
        <div
          className="modalContent"
          style={{ width }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modalHeader">
            <span className="modalTitle">{title}</span>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/lounges/close-cross.svg"
              alt="Close"
              className="closeIcon"
              onClick={() => this.handleClose()}
            />
          </div>
          <div className="modalBody">{children}</div>
        </div>
      </div>
    );
  }
}

export default PopUpModal;
