import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker styles
import "./fmlmPaymentSummarySearchFormMweb.scss";
import { routeTypes } from "../../config";
import SearchCityOverlayMweb from "../searchCityOverlayMweb";

class FmlmPaymentSummarySearchFormMweb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCity: "",
      fromCityId: "",
      toCity: "",
      toCityId: "",
      tripId: "",
      fromDate: null,
      toDate: null,
      showCalendar: false,
      calendarTarget: null,
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
      fromCities: [],
      toCities: [],
      isAssetTypeSelect: false,
      showAssetTypeSelection: false,
      searchQuery: "",
      assetType: "",
      assetTypeOptions: [],
      routeType: "",
    };
  }

  async componentDidMount() {
    const { trips } = this.props;
    const fromCities = [...new Set(trips.map((trip) => trip.from))];
    const toCities = [...new Set(trips.map((trip) => trip.to))];
    this.setState({
      fromCities,
      toCities,
      fromCity: this.props.fromCityFilter ? this.props.fromCityFilter : "",
      toCity: this.props.toCityFilter ? this.props.toCityFilter : "",
      fromCityId: this.props.fromCityId ? this.props.fromCityId : "",
      toCityId: this.props.toCityId ? this.props.toCityId : "",
      fromDate: this.props.fromDateFilter ? this.props.fromDateFilter : "",
      toDate: this.props.toDateFilter ? this.props.toDateFilter : "",
      assetType: this.props.assetTypeFilter ? this.props.assetTypeFilter : "",
      assetTypeOptions: this.props.assetTypes,
      routeType: this.props.routeTypeFilter ? this.props.routeTypeFilter : "",
    });
  }

  closeOverlay = () => {
    this.setState({ showCitySelection: false });
  };

  setFilters = async () => {
    const { fromCity, toCity, fromCityId, toCityId, routeType, assetType } =
      this.state;
    const { applySearchFiltersMweb, onCloseDrawer } = this.props;
    const searchFiltersApplied = {
      fromCity: fromCity,
      toCity: toCity,
      routeType: routeType,
      assetType: assetType,
      fromCityId: fromCityId,
      toCityId: toCityId,
    };
    onCloseDrawer();
    await applySearchFiltersMweb(searchFiltersApplied);
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  onCitySelect = (city) => {
    const { isFromSelect, isToSelect } = this.state;
    if (isFromSelect) {
      this.setState({ fromCity: city.name, fromCityId: city._id });
    } else if (isToSelect) {
      this.setState({ toCity: city.name, toCityId: city._id });
    }
    this.setState({
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
    });
  };

  handleCitySwap = () => {
    this.setState((prevState) => ({
      fromCity: prevState.toCity,
      toCity: prevState.fromCity,
      fromCityId: prevState.toCityId,
      toCityId: prevState.fromCityId,
    }));
  };

  onAssetTypeSelect = (assetType) => {
    this.setState({
      assetType: assetType,
      showAssetTypeSelection: false,
      isAssetTypeSelect: false,
      searchQuery: "",
    });
  };

  handleRouteTypeSelection = (routeType) => {
    this.setState({ routeType: routeType });
  };

  render() {
    const {
      showCitySelection,
      isFromSelect,
      fromCities,
      isToSelect,
      toCities,
      assetType,
      isAssetTypeSelect,
      showAssetTypeSelection,
      searchQuery,
      assetTypeOptions,
      routeType,
    } = this.state;
    return (
      <div className="fmlm-payment-summary-filters">
        <div className="routeTypeOptions">
          <div className="label">
            <span>By Route Type:</span>
          </div>
          <div
            className="routeType"
            onClick={() => this.handleRouteTypeSelection(routeTypes[0])}
            style={
              routeType && routeType === routeTypes[0]
                ? { color: "#FFFFFF", backgroundColor: "#7B2AFF" }
                : null
            }
          >
            {routeTypes[0]}
          </div>
          <div
            className="routeType"
            onClick={() => this.handleRouteTypeSelection(routeTypes[1])}
            style={
              routeType && routeType === routeTypes[1]
                ? { color: "#FFFFFF", backgroundColor: "#7B2AFF" }
                : null
            }
          >
            {routeTypes[1]}
          </div>
        </div>
        <div className="assetTypeOptions">
          <span className="label">By Asset Type:</span>
          <div className="inputWrapper">
            <input
              type="text"
              placeholder="Select An Option"
              className="assetTypeInput"
              value={
                assetType
                  ? assetType
                  : isAssetTypeSelect && searchQuery
                  ? searchQuery
                  : ""
              }
              onChange={this.handleSearchChange}
              onClick={() =>
                this.setState({
                  showAssetTypeSelection: true,
                  isAssetTypeSelect: true,
                  assetType: "",
                })
              }
            />
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/searchbar/dropdownIcon.svg"
              alt="Dropdown Icon"
              className="dropdownIcon"
              onClick={() =>
                this.setState({
                  showAssetTypeSelection: true,
                  isAssetTypeSelect: true,
                  assetType: "",
                })
              }
            />
          </div>
          {showAssetTypeSelection && isAssetTypeSelect && (
            <ul className="selectDropdown">
              {assetTypeOptions
                .filter((assetType) =>
                  assetType.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((assetType, index) => (
                  <li
                    key={index}
                    onClick={() => this.onAssetTypeSelect(assetType)}
                  >
                    {assetType}
                  </li>
                ))}
            </ul>
          )}
        </div>

        <span className="label" style={{ fontSize: "12px", color: "#454545" }}>
          By Route:
        </span>
        <div
          className="filter-field"
          onClick={() =>
            this.setState({ showCitySelection: true, isFromSelect: true })
          }
        >
          <input
            type="text"
            placeholder="From City"
            className="filter-input"
            value={this.state.fromCity}
            readOnly
          />
        </div>

        <img
          src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/swapicon.svg"
          className="switch-icon"
          alt="swap icon"
          onClick={this.handleCitySwap}
        />

        <div
          className="filter-field"
          onClick={() =>
            this.setState({ showCitySelection: true, isToSelect: true })
          }
        >
          <input
            type="text"
            placeholder="To City"
            className="filter-input"
            value={this.state.toCity}
            readOnly
          />
        </div>

        <div className="search-btn">
          <button onClick={() => this.setFilters()}>Apply Filters</button>
        </div>

        {showCitySelection ? (
          <SearchCityOverlayMweb
            cities={isFromSelect ? fromCities : isToSelect ? toCities : []}
            closeOverlay={this.closeOverlay}
            onCitySelect={this.onCitySelect}
          />
        ) : null}
      </div>
    );
  }
}

export default FmlmPaymentSummarySearchFormMweb;
