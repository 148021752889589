import React, { Component } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import jwt from "jsonwebtoken";
import "./sideMenu.scss";
import { operatorType } from "../../config";

class SideMenu extends Component {
  state = {
    active: 0,
    logout: false,
  };

  async componentDidMount() {
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const userMobileNo = decodedToken.mobileNo;
    const userName = decodedToken.name || decodedToken.mgName;
    const userType = decodedToken.userType;
    this.setState({
      userMobileNo: userMobileNo,
      userName: userName,
      userType: userType,
    });
  }

  hideSideBarOnClickMobile = () => {
    const { toggleSideMenu } = this.props;
    if (typeof toggleSideMenu === "function") {
      return toggleSideMenu();
    }
  };

  handleLinkClick = (activeItemIndex) => {
    this.setState({ active: activeItemIndex }, this.hideSideBarOnClickMobile);
  };

  deleteToken = () => {
    // window.history.push("login");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
  };

  render() {
    const { active, userMobileNo, userName, userType } = this.state;

    const activeLink = Math.round(this.props.active);
    const activeItem = active || activeLink;
    const activeItemClass = "menuItem active";
    const defaultItemClass = "menuItem";

    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);

    return (
      <div id="SideNav">
        <div className="menu">
          <Link
            to="/profile"
            onClick={() => this.handleLinkClick(3)}
            style={{ textDecoration: "none" }}
          >
            <div
              className={cx(
                activeItem === 3 ? activeItemClass : defaultItemClass
              )}
              style={{ marginTop: "-30px" }}
            >
              <div className="menuContent">
                <span className="menuIcon">
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/profile.svg"
                    alt="profile icon"
                  />
                </span>
                <div
                  className="menuItemFont"
                  style={{ marginLeft: "25px", marginTop: "25px" }}
                >
                  <div style={{ textAlign: "left" }}>
                    <span>{userName ? `Hi ${userName}` : `Hi Operator`}</span>
                  </div>
                  <div style={{ marginTop: "10px", textAlign: "left" }}>
                    <span style={{ color: "#7D7D7D" }}>{userMobileNo}</span>
                  </div>
                </div>
              </div>
              <img
                className="rightArrow"
                style={{ marginTop: "20px" }}
                src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
              />
            </div>
          </Link>

          <Link
            to="/"
            onClick={() => this.handleLinkClick(1)}
            style={{ textDecoration: "none" }}
          >
            <div
              className={cx(
                activeItem === 1 ? activeItemClass : defaultItemClass
              )}
            >
              <div className="menuContent">
                <span className="menuIcon">
                  <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/home-outline1.svg"
                    alt="menu icon"
                  />
                </span>
                <div className="menuItemFont">Home</div>
              </div>
              <img
                className="rightArrow"
                src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
              />
            </div>
          </Link>

          {userType && userType === operatorType.CABOWNER ? (
            <>
              <Link
                to="/trips"
                onClick={() => this.handleLinkClick(6)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 6 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/car-outline.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Trips</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
              <Link
                to="/fmlmPaymentSummary"
                onClick={() => this.handleLinkClick(6)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 4 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/rupee/ruppeblack.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Payment Summary</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
            </>
          ) : decodedToken.isMGUser && userType === operatorType.BUSOWNER ? (
            <>
              <Link
                to="/inspectionDataDetails"
                onClick={() => this.handleLinkClick(2)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 2 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/clipboard-outline2.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Inspection Data</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>

              <Link
                to="/auditDataDetails"
                onClick={() => this.handleLinkClick(4)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 4 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/sidemenu/analytics-outline2.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Audit Data</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>

              <Link
                to="/FeedbackDetails"
                onClick={() => this.handleLinkClick(5)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 5 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/chaticon.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Feedback</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/operatorDealsDetails"
                onClick={() => this.handleLinkClick(2)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 2 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/clipboard-outline2.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Operator Deals</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>

              <Link
                to="/offlineCommission"
                onClick={() => this.handleLinkClick(4)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 4 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/sidemenu/analytics-outline2.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Offline Commission</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
              <Link
                to="/seatFare"
                onClick={() => this.handleLinkClick(5)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 5 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/chaticon.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Offline Seat Fare</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
              <Link
                to="/payouts"
                onClick={() => this.handleLinkClick(5)}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={cx(
                    activeItem === 5 ? activeItemClass : defaultItemClass
                  )}
                >
                  <div className="menuContent">
                    <span className="menuIcon">
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/chaticon.svg"
                        alt="menu icon"
                      />
                    </span>
                    <div className="menuItemFont">Payouts</div>
                  </div>
                  <img
                    className="rightArrow"
                    src="https://d1flzashw70bti.cloudfront.net/original/images/opdas/sidemenu/rightangle.svg"
                  />
                </div>
              </Link>
            </>
          )}
        </div>

        <Link to="/login" className="menuItemFont" onClick={this.deleteToken}>
          <button
            className="confirmButton"
            style={{
              width: "93%",
              backgroundColor: "#ee6f6d",
              fontStyle: "bold",
              fontWeight: "700",
              border: "none",
              borderRadius: "8px",
            }}
          >
            <span className="text">Logout</span>
          </button>
        </Link>
      </div>
    );
  }
}

export default SideMenu;
