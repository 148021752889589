import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker styles
import { format } from "date-fns"; // Import date-fns for formatting
import "./tripSearchFilters.scss";
import { calendarTimeToUnixInMills } from "../../utils/common";
import SearchCityOverlayMweb from "../searchCityOverlayMweb";

class TripSearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCity: "",
      fromCityId: "",
      toCity: "",
      toCityId: "",
      tripId: "",
      fromDate: null,
      toDate: null,
      showCalendar: false,
      calendarTarget: null,
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
      fromCities: [],
      toCities: [],
    };
  }

  async componentDidMount() {
    const { trips } = this.props;
    const fromCities = [...new Set(trips.map((trip) => trip.from))];
    const toCities = [...new Set(trips.map((trip) => trip.to))];
    this.setState({
      fromCities,
      toCities,
      fromCity: this.props.fromCityFilter ? this.props.fromCityFilter : "",
      toCity: this.props.toCityFilter ? this.props.toCityFilter : "",
      fromCityId: this.props.fromCityId ? this.props.fromCityId : "",
      toCityId: this.props.toCityId ? this.props.toCityId : "",
      fromDate: this.props.fromDateFilter ? this.props.fromDateFilter : "",
      toDate: this.props.toDateFilter ? this.props.toDateFilter : "",
      tripId: this.props.tripIdFilter ? this.props.tripIdFilter : "",
    });
  }

  handleCitySwap = () => {
    this.setState((prevState) => ({
      fromCity: prevState.toCity,
      toCity: prevState.fromCity,
      fromCityId: prevState.toCityId,
      toCityId: prevState.fromCityId,
    }));
  };

  handleDateChange = (date) => {
    const { calendarTarget } = this.state;
    let timestamp = calendarTimeToUnixInMills(date); // Convert date to Unix timestamp

    // If selecting "toDate", adjust time to 23:59:59
    if (calendarTarget === "toDate") {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
      timestamp = calendarTimeToUnixInMills(endOfDay);
    }

    this.setState(
      {
        [calendarTarget]: timestamp,
        showCalendar: false, // Close the calendar after selection
      },
      () => {
        // If 'fromDate' is selected, open 'toDate' calendar afterward
        if (calendarTarget === "fromDate") {
          this.setState({ calendarTarget: "toDate", showCalendar: false });
        }
      }
    );
  };

  openCalendar = (target) => {
    this.setState({
      showCalendar: true,
      calendarTarget: target,
    });
  };

  formatDate = (date) => {
    return date ? format(date, "d MMM yyyy") : "Select Date";
  };

  closeOverlay = () => {
    this.setState({ showCitySelection: false });
  };

  onCitySelect = (city) => {
    const { isFromSelect, isToSelect } = this.state;
    if (isFromSelect) {
      this.setState({ fromCity: city.name, fromCityId: city._id });
    } else if (isToSelect) {
      this.setState({ toCity: city.name, toCityId: city._id });
    }
    this.setState({
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
    });
  };

  setFilters = async () => {
    const { fromCity, toCity, fromDate, fromCityId, toCityId, toDate, tripId } =
      this.state;
    const { applySearchFilters, onCloseDrawer } = this.props;
    const searchFiltersApplied = {
      fromCity: fromCity,
      toCity: toCity,
      fromDate: fromDate,
      toDate: toDate,
      fromCityId: fromCityId,
      toCityId: toCityId,
      tripId: tripId,
    };
    onCloseDrawer();
    await applySearchFilters(searchFiltersApplied);
  };

  render() {
    const {
      fromDate,
      toDate,
      showCalendar,
      calendarTarget,
      showCitySelection,
      isFromSelect,
      fromCities,
      isToSelect,
      toCities,
    } = this.state;
    return (
      <div className="trip-search-filters">
        <div
          className="filter-field"
          onClick={() =>
            this.setState({ showCitySelection: true, isFromSelect: true })
          }
        >
          <input
            type="text"
            placeholder="From City"
            className="filter-input"
            value={this.state.fromCity}
            readOnly
          />
        </div>

        <img
          src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/swapicon.svg"
          className="switch-icon"
          alt="swap icon"
          onClick={this.handleCitySwap}
        />

        <div
          className="filter-field"
          onClick={() =>
            this.setState({ showCitySelection: true, isToSelect: true })
          }
        >
          <input
            type="text"
            placeholder="To City"
            className="filter-input"
            value={this.state.toCity}
            readOnly
          />
        </div>

        <div className="filter-field">
          <input
            type="text"
            placeholder="Search by trip"
            className="filter-input"
            value={this.state.tripId}
            onChange={(e) => this.setState({ tripId: e.target.value })}
          />
        </div>

        <div className="filter-field date-range">
          <img
            className="calendar-icon"
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/calendar-outline1.svg"
          />
          <div
            className="date-text"
            onClick={() => this.openCalendar("fromDate")}
          >
            {this.formatDate(fromDate)}
          </div>
          <span> - </span>
          <div
            className="date-text"
            onClick={() => this.openCalendar("toDate")}
          >
            {this.formatDate(toDate)}
          </div>

          {showCalendar && (
            <div className="calendar-modal">
              <DatePicker
                selected={calendarTarget === "fromDate" ? fromDate : toDate}
                onChange={this.handleDateChange}
                inline
                className="custom-datepicker"
                calendarClassName="centered-datepicker"
              />
            </div>
          )}
        </div>

        <div className="search-btn">
          <button onClick={() => this.setFilters()}>Search</button>
        </div>

        {showCitySelection ? (
          <SearchCityOverlayMweb
            cities={isFromSelect ? fromCities : isToSelect ? toCities : []}
            closeOverlay={this.closeOverlay}
            onCitySelect={this.onCitySelect}
          />
        ) : null}
      </div>
    );
  }
}

export default TripSearchFilters;
