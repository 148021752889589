import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import EmptyView from "../../components/emptyView";
import Loader from "../../utils/loader";
import FmlmPaymentSummarySearchForm from "../../components/searchForms/fmlmPaymentSummarySearchForm";
import { defaultSearchDateRanges } from "../../config";
import FmlmPaymentSummaryTable from "../../components/tables/fmlmPaymentSummaryTable";
import EarningsView from "../../components/earningsView";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import BottomDrawer from "../../components/Layout/bottomDrawer";
import FmlmPaymentSummaryTile from "../../components/tiles/fmlmPaymentSummaryTIle.js";
import FmlmPaymentSummarySearchFormMweb from "../../components/searchForms/fmlmPaymentSummarySearchFormMweb.js";
import DateSearchBarMweb from "../../components/searchForms/dateSearchBarMweb.js";
import AppliedFiltersTabs from "../../components/appliedFiltersTabs/index.js";
const { fmlmPaymentSummarySearch } = defaultSearchDateRanges;

class FmLmPaymentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isMobile: false,
      showSideMenu: false,
      trips: [],
      isBottomDrawerOpen: false,
      fromDateFilter: fmlmPaymentSummarySearch.fromDate,
      toDateFilter: fmlmPaymentSummarySearch.toDate,
      fromCityFilter: "",
      toCityFilter: "",
      tripIdFilter: "",
      routeTypeFilter: "",
      assetTypeFilter: "",
      assetTypes: [],
      isSearchFiltersApplied: false,
      totalEarnings: 0,
      avgTripEarning: 0,
      highestTripEarning: 0,
      totalTrips: 0,
    };
  }

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const { fromDateFilter, toDateFilter } = this.state;
      const options = {
        method: "get",
      };

      let path = `/cabOperator/assetType`;
      const assetTypes = await Fetch(path, options);

      path = `/cabOperator/fmlmPaymentSummary`;
      if (fromDateFilter && toDateFilter) {
        path += `?fromDate=${fromDateFilter}&toDate=${toDateFilter}`;
      }
      const contractsData = await Fetch(path, options);
      const {
        tripContracts,
        totalEarnings,
        avgTripEarning,
        highestTripEarning,
        totalTrips,
      } = contractsData;

      this.setState({
        loading: false,
        isMobile,
        trips:
          tripContracts && Array.isArray(tripContracts) ? tripContracts : [],
        assetTypes: assetTypes,
        totalEarnings,
        avgTripEarning,
        highestTripEarning,
        totalTrips,
      });
    } catch (err) {
      console.log(err);
    }
  }

  applySearchFiltersDweb = async (searchFiltersApplied) => {
    this.setState({
      fromDateFilter: searchFiltersApplied.fromDate,
      toDateFilter: searchFiltersApplied.toDate,
      fromCityFilter: searchFiltersApplied.fromCity,
      toCityFilter: searchFiltersApplied.toCity,
      routeTypeFilter: searchFiltersApplied.routeType,
      assetTypeFilter: searchFiltersApplied.assetType,
      fromCityId: searchFiltersApplied.fromCityId,
      toCityId: searchFiltersApplied.toCityId,
      isSearchFiltersApplied: true,
    });

    const options = {
      method: "get",
    };
    let path = `/cabOperator/fmlmPaymentSummary?routeType=${searchFiltersApplied.routeType}&assetType=${searchFiltersApplied.assetType}`;
    if (searchFiltersApplied.fromDate && searchFiltersApplied.toDate) {
      path += `&fromDate=${searchFiltersApplied.fromDate}&toDate=${searchFiltersApplied.toDate}`;
    }
    if (searchFiltersApplied.fromCity && searchFiltersApplied.toCity) {
      path += `&fromCityId=${searchFiltersApplied.fromCityId}&toCityId=${searchFiltersApplied.toCityId}`;
    }
    const contractsData = await Fetch(path, options);
    const {
      tripContracts,
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    } = contractsData;

    this.setState({
      trips: tripContracts && Array.isArray(tripContracts) ? tripContracts : [],
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    });
  };

  applySearchFiltersMweb = async (searchFiltersApplied) => {
    const { fromDateFilter, toDateFilter } = this.state;

    this.setState({
      fromCityFilter: searchFiltersApplied.fromCity,
      toCityFilter: searchFiltersApplied.toCity,
      routeTypeFilter: searchFiltersApplied.routeType,
      assetTypeFilter: searchFiltersApplied.assetType,
      fromCityId: searchFiltersApplied.fromCityId,
      toCityId: searchFiltersApplied.toCityId,
      isSearchFiltersApplied: true,
    });

    const queryParams = new URLSearchParams();

    if (searchFiltersApplied.routeType)
      queryParams.append("routeType", searchFiltersApplied.routeType);
    if (searchFiltersApplied.fromCityId)
      queryParams.append("fromCityId", searchFiltersApplied.fromCityId);
    if (searchFiltersApplied.toCityId)
      queryParams.append("toCityId", searchFiltersApplied.toCityId);
    if (searchFiltersApplied.assetType)
      queryParams.append("assetType", searchFiltersApplied.assetType);
    if (fromDateFilter) queryParams.append("fromDate", fromDateFilter);
    if (toDateFilter) queryParams.append("toDate", toDateFilter);

    const path = `/cabOperator/fmlmPaymentSummary?${queryParams.toString()}`;

    const options = { method: "GET" };
    const contractsData = await Fetch(path, options);
    const {
      tripContracts,
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    } = contractsData;

    this.setState({
      trips: tripContracts && Array.isArray(tripContracts) ? tripContracts : [],
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    });
  };

  applyDateFilter = async (fromDate, toDate) => {
    const { fromCityId, toCityId, routeTypeFilter, assetTypeFilter } =
      this.state;

    this.setState({
      fromDateFilter: fromDate,
      toDateFilter: toDate,
      isSearchFiltersApplied: true,
    });

    const queryParams = new URLSearchParams();

    if (fromDate) queryParams.append("fromDate", fromDate);
    if (toDate) queryParams.append("toDate", toDate);
    if (routeTypeFilter) queryParams.append("routeType", routeTypeFilter);
    if (assetTypeFilter) queryParams.append("assetType", assetTypeFilter);
    if (fromCityId) queryParams.append("fromCityId", fromCityId);
    if (toCityId) queryParams.append("toCityId", toCityId);

    const path = `/cabOperator/fmlmPaymentSummary?${queryParams.toString()}`;

    const options = { method: "GET" };
    const contractsData = await Fetch(path, options);
    const {
      tripContracts,
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    } = contractsData;

    this.setState({
      trips: tripContracts && Array.isArray(tripContracts) ? tripContracts : [],
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    });
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  toggleBottomDrawer = () => {
    this.setState((prevState) => ({
      isBottomDrawerOpen: !prevState.isBottomDrawerOpen,
    }));
  };

  onRemoveFilters = (filter) => {
    if (filter.fitlerType === "FROM_AND_TO_CITY_FILTER") {
      this.setState(
        {
          fromCityFilter: "",
          toCityFilter: "",
          fromCityId: "",
          toCityId: "",
        },
        () => {
          this.applyDateFilter(
            this.state.fromDateFilter,
            this.state.toDateFilter
          );
        }
      );
    }
    if (filter.fitlerType === "ROUTE_TYPE_FILTER") {
      this.setState(
        {
          routeTypeFilter: "",
        },
        () => {
          this.applyDateFilter(
            this.state.fromDateFilter,
            this.state.toDateFilter
          );
        }
      );
    }
    if (filter.fitlerType === "ASSET_TYPE_FILTER") {
      this.setState(
        {
          assetTypeFilter: "",
        },
        () => {
          this.applyDateFilter(
            this.state.fromDateFilter,
            this.state.toDateFilter
          );
        }
      );
    }
  };

  resetAllSearchFilters = async () => {
    this.setState({
      fromDateFilter: fmlmPaymentSummarySearch.fromDate,
      toDateFilter: fmlmPaymentSummarySearch.toDate,
      fromCityId: "",
      toCityId: "",
      fromCityFilter: "",
      toCityFilter: "",
      routeTypeFilter: "",
      assetTypeFilter: "",
      filtersAppliedCount: 0,
    });
    const path = `/cabOperator/fmlmPaymentSummary?fromDate=${fmlmPaymentSummarySearch.fromDate}&toDate=${fmlmPaymentSummarySearch.toDate}`;
    const options = { method: "GET" };
    const contractsData = await Fetch(path, options);
    const {
      tripContracts,
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    } = contractsData;
    this.setState({
      trips: tripContracts && Array.isArray(tripContracts) ? tripContracts : [],
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    });
  };

  render() {
    const {
      showSideMenu,
      isMobile,
      loading,
      isBottomDrawerOpen,
      trips,
      fromCityFilter,
      toCityFilter,
      fromDateFilter,
      toDateFilter,
      routeTypeFilter,
      assetTypeFilter,
      assetTypes,
      isSearchFiltersApplied,
      fromCityId,
      toCityId,
      totalEarnings,
      avgTripEarning,
      highestTripEarning,
      totalTrips,
    } = this.state;

    const active = 4;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }

    return isMobile ? (
      <div style={{ overflowX: "hidden" }}>
        <MobileMainHeader
          toggleSideMenu={this.toggleSideMenu}
          hasSubHeader={true}
          toggleBottomDrawer={this.toggleBottomDrawer}
        />

        <DateSearchBarMweb
          fromDateFilter={fromDateFilter}
          toDateFilter={toDateFilter}
          applyDateFilter={this.applyDateFilter}
        />

        <EarningsView
          totalEarnings={totalEarnings}
          avgTripEarning={avgTripEarning}
          highestTripEarning={highestTripEarning}
          totalTrips={totalTrips}
        />

        {isSearchFiltersApplied ? (
          <AppliedFiltersTabs
            fromCityFilter={fromCityFilter}
            toCityFilter={toCityFilter}
            onRemoveFilters={this.onRemoveFilters}
            addMarginTop={false}
            routeTypeFilter={routeTypeFilter}
            assetTypeFilter={assetTypeFilter}
          />
        ) : null}

        {trips.length > 0 ? (
          trips.map((trip, index) => (
            <FmlmPaymentSummaryTile
              key={trip.id}
              index={index}
              tripData={trip}
              isAnyFilterApplied={isSearchFiltersApplied}
            />
          ))
        ) : (
          <div>
            <EmptyView
              message={
                isSearchFiltersApplied && trips.length === 0
                  ? "Trips Not Found"
                  : "No Trips Assigned"
              }
            />
          </div>
        )}

        {showSideMenu ? (
          <MenuSlideDrawer
            toggleSideMenu={this.toggleSideMenu}
            showSideMenu={showSideMenu}
            isMobile={isMobile}
          >
            <SideMenu active={active} toggleSideMenu={this.toggleSideMenu} />
          </MenuSlideDrawer>
        ) : null}

        {isBottomDrawerOpen ? (
          <BottomDrawer
            heading="Filters"
            isOpen={isBottomDrawerOpen}
            onClose={this.toggleBottomDrawer}
          >
            <FmlmPaymentSummarySearchFormMweb
              onCloseDrawer={this.toggleBottomDrawer}
              trips={trips}
              applySearchFiltersMweb={this.applySearchFiltersMweb}
              fromCityFilter={fromCityFilter}
              toCityFilter={toCityFilter}
              fromCityId={fromCityId}
              toCityId={toCityId}
              fromDateFilter={fromDateFilter}
              toDateFilter={toDateFilter}
              routeTypeFilter={routeTypeFilter}
              assetTypeFilter={assetTypeFilter}
              assetTypes={assetTypes}
            />
          </BottomDrawer>
        ) : null}
      </div>
    ) : (
      <>
        <Navbar selectedKey={active} isMobile={false} />
        <div className="container">
          <div className="header">
            <p className="headings">Payment Summary</p>
          </div>
          <FmlmPaymentSummarySearchForm
            trips={trips}
            applySearchFiltersDweb={this.applySearchFiltersDweb}
            resetAllSearchFilters={this.resetAllSearchFilters}
            fromCityFilter={fromCityFilter}
            toCityFilter={toCityFilter}
            fromDateFilter={fromDateFilter}
            toDateFilter={toDateFilter}
            routeTypeFilter={routeTypeFilter}
            assetTypeFilter={assetTypeFilter}
            assetTypes={assetTypes}
            fmlmPaymentSummarySearch={fmlmPaymentSummarySearch}
          />
          <EarningsView
            totalEarnings={totalEarnings}
            avgTripEarning={avgTripEarning}
            highestTripEarning={highestTripEarning}
            totalTrips={totalTrips}
          />
          {trips.length > 0 ? (
            <FmlmPaymentSummaryTable
              trips={trips}
              refreshTripsData={this.refreshTripsData}
            />
          ) : (
            <div>
              <EmptyView
                message={
                  isSearchFiltersApplied && trips.length === 0
                    ? "Trips Not Found"
                    : "No Trips Assigned"
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default FmLmPaymentSummary;
