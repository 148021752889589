import React, { Component } from "react";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import Navbar from "../../components/newNavBar";
import Loader from "../../utils/loader";
import { Fetch } from "../../utils/fetch";
import "../home/home.scss";
import TripTile from "../../components/tiles/tripTile";
import BottomDrawer from "../../components/Layout/bottomDrawer";
import TripSearchFilters from "../../components/tripSearchFilters.js";
import AppliedFiltersTabs from "../../components/appliedFiltersTabs/index.js";
import EmptyView from "../../components/emptyView/index.js";
import TripSearchFiltersDweb from "../../components/tripSearchFilters.js/tripSearchFiltersDweb.js";
import TripTable from "../../components/tables/tripTable.js";
import { defaultSearchDateRanges } from "../../config/index.js";
const { fmlmTripSearch } = defaultSearchDateRanges;
class Trips extends Component {
  state = {
    loading: true,
    isMobile: false,
    showSideMenu: false,
    trips: [],
    tripsIn12HrsRange: [],
    isBottomDrawerOpen: false,
    fromDateFilter: "",
    toDateFilter: "",
    fromCityFilter: "",
    toCityFilter: "",
    tripIdFilter: "",
    filtersAppliedCount: 0,
    isFromAndCityFilterApplied: false,
    isFromAndToDateFilterApplied: false,
    isTripIdFilterApplied: false,
  };

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);

      const path = `/cabOperator/trips?fromDate=${fmlmTripSearch.fromDate}&toDate=${fmlmTripSearch.toDate}`;
      const options = {
        method: "get",
      };
      const assignedTrips = await Fetch(path, options);

      this.setState({
        loading: false,
        isMobile,
        trips:
          assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
      });

      const sortedTrips = this.sortTripsLogic();
      this.setState({ trips: sortedTrips });
    } catch (err) {
      console.log(err);
    }
  }

  refreshTripsData = async () => {
    try {
      const path = `/cabOperator/trips?fromDate=${fmlmTripSearch.fromDate}&toDate=${fmlmTripSearch.toDate}`;
      const options = {
        method: "get",
      };
      const assignedTrips = await Fetch(path, options);
      this.setState({
        trips:
          assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
      });

      const tripsIn12HrsRange = this.sortTripsLogic();
      this.setState({ tripsIn12HrsRange: tripsIn12HrsRange });
    } catch (error) {
      console.log(error);
    }
  };

  sortTripsLogic = () => {
    const { trips } = this.state;
    const currentTime = new Date().getTime(); // Current time in milliseconds
    // const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

    // Filter trips within 12 hours before or after the current time
    const filteredAndSortedTrips = trips.sort((a, b) => {
      // Calculate the time difference for each trip's startTime relative to the current time
      const aDiff = a.startTime - currentTime; // Difference for trip 'a'
      const bDiff = b.startTime - currentTime; // Difference for trip 'b'

      // Sorting logic:
      if (aDiff >= 0 && bDiff >= 0) {
        // Both trips have start times ahead of the current time
        // Sort in ascending order of startTime - currentTime
        return aDiff - bDiff;
      } else if (aDiff >= 0 && bDiff < 0) {
        // Trip 'a' is ahead of current time, but trip 'b' has crossed
        // 'a' should come before 'b'
        return -1;
      } else if (aDiff < 0 && bDiff >= 0) {
        // Trip 'b' is ahead of current time, but trip 'a' has crossed
        // 'b' should come before 'a'
        return 1;
      } else {
        // Both trips have start times that have already crossed the current time
        // Sort in ascending order of the absolute difference |startTime - currentTime|
        return Math.abs(aDiff) - Math.abs(bDiff);
      }
    });
    return filteredAndSortedTrips;
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  toggleBottomDrawer = () => {
    this.setState((prevState) => ({
      isBottomDrawerOpen: !prevState.isBottomDrawerOpen,
    }));
  };

  applySearchFilters = async (searchFiltersApplied) => {
    let {
      filtersAppliedCount,
      isFromAndCityFilterApplied,
      isFromAndToDateFilterApplied,
      isTripIdFilterApplied,
    } = this.state;
    const { fromCity, toCity, fromDate, toDate, tripId, fromCityId, toCityId } =
      searchFiltersApplied;

    if (fromCityId && toCityId && !isFromAndCityFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isFromAndCityFilterApplied: true,
      });
    }
    if (fromDate && toDate && !isFromAndToDateFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isFromAndToDateFilterApplied: true,
      });
    }
    if (tripId && !isTripIdFilterApplied) {
      this.setState({
        filtersAppliedCount: ++filtersAppliedCount,
        isTripIdFilterApplied: true,
      });
    }

    const queryParams = new URLSearchParams();

    if (fromCityId) queryParams.append("fromCityId", fromCityId);
    if (toCityId) queryParams.append("toCityId", toCityId);
    if (fromDate) queryParams.append("fromDate", fromDate);
    if (toDate) queryParams.append("toDate", toDate);
    if (tripId) queryParams.append("tripId", tripId);

    const path = `/cabOperator/trips?${queryParams.toString()}`;
    const options = {
      method: "get",
    };
    const assignedTrips = await Fetch(path, options);

    this.setState({
      fromCityFilter: fromCity,
      toCityFilter: toCity,
      fromCityId: fromCityId,
      toCityId: toCityId,
      fromDateFilter: fromDate,
      toDateFilter: toDate,
      tripIdFilter: tripId,
      trips: assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
    });
  };

  onRemoveFilters = async (filter) => {
    const {
      filtersAppliedCount,
      fromDateFilter,
      toDateFilter,
      tripIdFilter,
      fromCityId,
      toCityId,
    } = this.state;
    const queryParams = new URLSearchParams();

    if (filter.fitlerType === "FROM_AND_TO_DATE_FILTER") {
      this.setState({
        fromDateFilter: "",
        toDateFilter: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isFromAndCityFilterApplied: false,
      });
      if (fromCityId) queryParams.append("fromCityId", fromCityId);
      if (toCityId) queryParams.append("toCityId", toCityId);
      if (tripIdFilter) queryParams.append("tripId", tripIdFilter);
    }

    if (filter.fitlerType === "FROM_AND_TO_CITY_FILTER") {
      this.setState({
        fromCityFilter: "",
        toCityFilter: "",
        fromCityId: "",
        toCityId: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isFromAndToDateFilterApplied: false,
      });
      if (fromDateFilter) queryParams.append("fromDate", fromDateFilter);
      if (toDateFilter) queryParams.append("toDate", toDateFilter);
      if (tripIdFilter) queryParams.append("tripId", tripIdFilter);
    }

    if (filter.fitlerType === "TRIP_ID_FILTER") {
      this.setState({
        tripIdFilter: "",
        filtersAppliedCount: filtersAppliedCount - 1,
        isTripIdFilterApplied: false,
      });
      if (fromCityId) queryParams.append("fromCityId", fromCityId);
      if (toCityId) queryParams.append("toCityId", toCityId);
      if (fromDateFilter) queryParams.append("fromDate", fromDateFilter);
      if (toDateFilter) queryParams.append("toDate", toDateFilter);
    }

    if (
      filtersAppliedCount - 1 === 0 &&
      (!queryParams.has("fromDate") || !queryParams.get("fromDate")) &&
      (!queryParams.has("toDate") || !queryParams.get("toDate"))
    ) {
      queryParams.set("fromDate", fmlmTripSearch.fromDate);
      queryParams.set("toDate", fmlmTripSearch.toDate);
    }

    const path = `/cabOperator/trips?${queryParams.toString()}`;
    const options = {
      method: "get",
    };
    const assignedTrips = await Fetch(path, options);
    this.setState({
      trips: assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
    });
    if (filtersAppliedCount - 1 === 0) {
      const sortedTrips = this.sortTripsLogic();
      this.setState({ trips: sortedTrips });
    }
  };

  resetAllSearchFilters = async () => {
    this.setState({
      fromDateFilter: "",
      toDateFilter: "",
      fromCityId: "",
      toCityId: "",
      fromCityFilter: "",
      toCityFilter: "",
      tripIdFilter: "",
      filtersAppliedCount: 0,
      isFromAndCityFilterApplied: false,
      isFromAndToDateFilterApplied: false,
      isTripIdFilterApplied: false,
    });
    const path = `/cabOperator/trips?fromDate=${fmlmTripSearch.fromDate}&toDate=${fmlmTripSearch.toDate}`;
    const options = {
      method: "get",
    };
    const assignedTrips = await Fetch(path, options);
    this.setState({
      trips: assignedTrips && Array.isArray(assignedTrips) ? assignedTrips : [],
    });
    const sortedTrips = this.sortTripsLogic();
    this.setState({ trips: sortedTrips });
  };

  render() {
    const {
      showSideMenu,
      isMobile,
      loading,
      isBottomDrawerOpen,
      trips,
      filtersAppliedCount,
      fromCityFilter,
      toCityFilter,
      fromDateFilter,
      toDateFilter,
      tripIdFilter,
      fromCityId,
      toCityId,
    } = this.state;
    let active = 6;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }

    return isMobile ? (
      <div style={{ overflowX: "hidden" }}>
        <MobileMainHeader
          toggleSideMenu={this.toggleSideMenu}
          hasSubHeader={true}
          toggleBottomDrawer={this.toggleBottomDrawer}
        />
        {filtersAppliedCount > 0 ? (
          <AppliedFiltersTabs
            fromCityFilter={fromCityFilter}
            toCityFilter={toCityFilter}
            fromDateFilter={fromDateFilter}
            toDateFilter={toDateFilter}
            tripIdFilter={tripIdFilter}
            onRemoveFilters={this.onRemoveFilters}
            addMarginTop={true}
          />
        ) : null}
        {trips.length > 0 ? (
          trips.map((trip, index) => (
            <TripTile
              key={trip.id}
              index={index}
              tripData={trip}
              isAnyFilterApplied={filtersAppliedCount > 0}
            />
          ))
        ) : (
          <div>
            <EmptyView
              message={
                filtersAppliedCount > 0
                  ? "Trips Not Found"
                  : "No Trips Assigned"
              }
            />
          </div>
        )}
        {showSideMenu ? (
          <MenuSlideDrawer
            toggleSideMenu={this.toggleSideMenu}
            showSideMenu={showSideMenu}
            isMobile={isMobile}
          >
            <SideMenu active={active} toggleSideMenu={this.toggleSideMenu} />
          </MenuSlideDrawer>
        ) : null}
        {isBottomDrawerOpen ? (
          <BottomDrawer
            heading="Search"
            isOpen={isBottomDrawerOpen}
            onClose={this.toggleBottomDrawer}
          >
            <TripSearchFilters
              onCloseDrawer={this.toggleBottomDrawer}
              trips={trips}
              applySearchFilters={this.applySearchFilters}
              fromCityFilter={fromCityFilter}
              toCityFilter={toCityFilter}
              fromCityId={fromCityId}
              toCityId={toCityId}
              fromDateFilter={fromDateFilter}
              toDateFilter={toDateFilter}
              tripIdFilter={tripIdFilter}
            />
          </BottomDrawer>
        ) : null}
      </div>
    ) : (
      <>
        <Navbar selectedKey={6} isMobile={false} />
        <div className="container">
          <div className="header">
            <p className="headings">Trips</p>
          </div>
          <TripSearchFiltersDweb
            trips={trips}
            applySearchFilters={this.applySearchFilters}
            resetAllSearchFilters={this.resetAllSearchFilters}
            fromCityFilter={fromCityFilter}
            toCityFilter={toCityFilter}
            fromDateFilter={fromDateFilter}
            toDateFilter={toDateFilter}
            tripIdFilter={tripIdFilter}
          />
          {trips.length > 0 ? (
            <TripTable trips={trips} refreshTripsData={this.refreshTripsData} />
          ) : (
            <div>
              <EmptyView
                message={
                  filtersAppliedCount > 0
                    ? "Trips Not Found"
                    : "No Trips Assigned"
                }
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Trips;
