import React, { Component } from "react";
import "./marshalDetailsMweb.scss";

class MarshalDetailsMweb extends Component {
  handleDial = (mobileNo) => {
    window.location.href = `tel:${mobileNo}`;
  };

  render() {
    const { connectingMarshal } = this.props;

    return (
      <div className="marshalDetails">
        {connectingMarshal &&
        connectingMarshal.name &&
        connectingMarshal.mobileNo ? (
          <>
            <div className="detailRow">
              <span className="detailLabel">Name:</span>
              <span className="detailValue">{connectingMarshal.name}</span>
            </div>
            <div className="detailRow">
              <span className="detailLabel">Mobile:</span>
              <span className="detailValue">{connectingMarshal.mobileNo}</span>
            </div>
            <button
              className="callButton"
              onClick={() => this.handleDial(connectingMarshal.mobileNo)}
            >
              Call
            </button>
          </>
        ) : (
          <div>Marshal Details Unavailable</div>
        )}
      </div>
    );
  }
}

export default MarshalDetailsMweb;
