import React, { Component } from "react";
import { formatDate, formatTime } from "../../utils/common";
import "./fmlmPaymentSummaryTable.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class FmlmPaymentSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
      showPopUp: false,
      rcNumber: "",
      driverMobileNo: "",
      driverName: "",
      showAssignDriverPopUp: false,
      isDriverAssigned: false,
      assignedTripDetails: {},
      tooltipVisible: false,
      tooltipPosition: { top: 0, left: 0 },
      marshalDetails: {},
      disableViewDetails: {}, // Track button disable state for each trip
      noMarshalDetails: {}, // Track trips where marshal details are unavailable
    };
  }

  componentDidMount() {
    toast.configure();
  }

  handleTooltipClick = (event) => {
    const { top, left, height } = event.target.getBoundingClientRect();

    this.setState((prevState) => ({
      tooltipVisible: !prevState.tooltipVisible,
      tooltipPosition: {
        top: top + height + 10 + window.scrollY,
        left: left - 222,
      },
    }));
  };

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  render() {
    const { trips } = this.props;
    const { tooltipVisible } = this.state;

    return (
      <>
        {tooltipVisible && (
          <div
            className="tooltipBox"
            style={{
              position: "absolute",
              top: this.state.tooltipPosition.top,
              left: this.state.tooltipPosition.left,
            }}
          >
            <div className="tooltipRow">
              <span>Contact Admin team for Assistance</span>
            </div>
          </div>
        )}

        <div className="tableContainer">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div>Route</div>
                </th>
                <th>
                  <div className="coloumnTitle">Trip ID</div>
                </th>
                <th>
                  <div className="coloumnTitle">Start Time & Date</div>
                </th>
                <th>
                  <div className="coloumnTitle">Amount</div>
                </th>
                <th>
                  <div className="coloumnTitle">Asset Type</div>
                </th>
                <th>
                  <div className="coloumnTitle">Route Type</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {trips.map((trip) => (
                <React.Fragment key={trip.id}>
                  <tr>
                    <td className="fromAndTo">{`${trip.from} - ${trip.to}`}</td>
                    <td className="tripId">
                      <span className="code">{trip.groupCode}</span>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/copyicon.svg"
                        alt="copy"
                        className="copyIcon"
                        onClick={() => this.copyToClipboard(trip.groupCode)}
                      />
                    </td>
                    <td>
                      <div className="timeAndDate">
                        <span className="time">
                          {formatTime(trip.startTime, "h:mm A")}
                        </span>
                        <span className="date">
                          {formatDate(trip.startTime, "D MMM YYYY")}
                        </span>
                      </div>
                    </td>
                    <td className="details">
                      {trip.startTime > Date.now() ? (
                        <>
                          <img
                            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/time-outline.svg"
                            alt="clock icon"
                          />
                          <span className="noOfBookingsText">
                            Trip Not Started
                          </span>
                        </>
                      ) : trip.isContractExpired ? (
                        <>
                          <span
                            className="noOfBookingsText"
                            style={{ color: "#DD800E" }}
                          >
                            Contract Invalid
                          </span>
                          <img
                            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/informationicon.svg"
                            alt="information icon"
                            onClick={this.handleTooltipClick}
                            style={{ cursor: "pointer" }}
                          />
                        </>
                      ) : (
                        <span className="noOfBookingsText">
                          ₹{trip.supplyCost}
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="cellConainer">{trip.seater || "-"}</div>
                    </td>
                    <td>
                      <div className="cellConainer">
                        <span>{trip.routeType}</span>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default FmlmPaymentSummaryTable;
