import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import "./profile.scss";
import { Button, Modal, Input, message, Select } from "antd";
import jwt from "jsonwebtoken";
import Loader from "../../utils/loader";
import PocDetails from "../../components/PocDetails";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import { operatorType } from "../../config";
const { TextArea } = Input;

class Profile extends Component {
  state = {
    profile: {},
    loading: true,
    isNavBarOpen: false,
    showPocModal: false,
    isEdit: false,
    name: "",
    mobile: null,
    area: "",
    showSideMenu: false,
  };

  async componentDidMount() {
    try {
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const userMobileNo = decodedToken.mobileNo;
      const userName = decodedToken.name || decodedToken.mgName;
      const userType = decodedToken.userType;
      this.setState({ userType: userType });
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const profile = await this.fetchProfile(userMobileNo, userType);
      const departments = [
        { value: "SALES", label: "SALES" },
        { value: "OPERATIONS", label: "OPERATIONS" },
        { value: "PICKUPS", label: "PICKUPS" },
      ];
      this.setState({
        profile,
        loading: false,
        userMobileNo,
        userName,
        isMobile,
        departments,
      });
    } catch (err) {
      console.log(err);
    }
  }

  fetchProfile = async (mobileNo, userType) => {
    let url = `/user/details?mobileNo=${mobileNo}`;
    if (userType === operatorType.CABOWNER) {
      url = `/mg/user/details?mobileNo=${mobileNo}`;
    }

    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      return response[0];
    }
    message.error("Something went wrong");
    return;
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange = (key, value) => {
    if (key === "mobile") {
      const re = /^[0-9\b]+$/;
      if (value.length < 11 && (value === "" || re.test(value))) {
        this.setState({ mobile: value });
      }
    } else {
      this.setState({ [key]: value });
    }
  };

  addPoc = async () => {
    const { name, area, mobile, profile, selectedIndex, isEdit, department } =
      this.state;
    let { pocList } = profile;
    if (selectedIndex !== undefined) {
      pocList[selectedIndex] = {
        name,
        area,
        department,
        mobile: mobile.toString(),
      };
    } else {
      let newData = {
        name,
        area,
        department,
        mobile: mobile.toString(),
      };
      if (pocList) {
        pocList.push(newData);
      } else {
        pocList = [];
        pocList.push(newData);
      }
    }
    const url = `/user/update`;
    const options = {
      method: "post",
      data: {
        pocList,
      },
    };

    const response = await Fetch(url, options);

    if (!Array.isArray(response)) {
      message.error("Something went wrong");
    }
    if (response) {
      if (isEdit) {
        message.success("POC is Updated");
      } else {
        message.success("POC is added");
      }
    }

    this.setState({
      profile: response[0],
      showPocModal: false,
      isEdit: false,
      name: "",
      mobile: null,
      area: "",
    });
  };

  editPoc = async (data) => {
    const { profile } = this.state;
    const { pocList } = profile;
    const { name, area, mobile, department } = pocList[data];
    this.setState({
      name,
      area,
      department,
      mobile,
      showPocModal: true,
      isEdit: true,
      selectedIndex: data,
    });
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  render() {
    const {
      showPocModal,
      profile,
      loading,
      name,
      mobile,
      area,
      userMobileNo,
      userName,
      isMobile,
      isEdit,
      department,
      departments,
      showSideMenu,
    } = this.state;

    let active = 3;

    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else if (isMobile) {
      return (
        <div class="container">
          <MobileMainHeader toggleSideMenu={this.toggleSideMenu} />

          {showSideMenu ? (
            <MenuSlideDrawer
              toggleSideMenu={this.toggleSideMenu}
              showSideMenu={showSideMenu}
              isMobile={isMobile}
            >
              <SideMenu
                active={active}
                toggleSideMenu={this.toggleSideMenu}
                isMobile={isMobile}
              />
            </MenuSlideDrawer>
          ) : null}
          <div className="home" style={{ paddingTop: "45px" }}>
            <p className="user">User Profile</p>
            <div className="Details">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashBoard/home/profilePhoto.svg"
                alt="profilePhoto"
              />
              <div>
                <div className="userDetail">
                  <span className="Bold">Hi {userName}</span>
                </div>
                <div className="userDetail">
                  <span className="Bold">Mobile Number : </span>
                  <span className="light">{userMobileNo}</span>
                </div>
                <div className="userDetail">
                  <span className="Bold">Email Id : </span>
                  <span className="light">{profile.email}</span>
                </div>
              </div>
            </div>
            <div className="PocHeader">
              <p className="user">POC Details</p>
              <div
                className="PocBtn"
                onClick={() => this.setState({ showPocModal: true })}
              >
                <p className="Poctxt">+ Add POC</p>
              </div>
            </div>
            <PocDetails
              editPoc={this.editPoc}
              details={profile.pocList}
              isMobile={isMobile}
            />
          </div>

          <Modal
            open={showPocModal}
            onCancel={() =>
              this.setState({
                showPocModal: false,
                isEdit: false,
                name: "",
                mobile: null,
                area: "",
              })
            }
            footer={null}
          >
            <div className="POCModal">
              <p className="modalHeader">{isEdit ? "Edit POC" : "Add POC"}</p>
              <p className="subHeader">Please enter the details </p>
              <TextArea
                autoSize
                value={name}
                onChange={(e) => this.handleChange("name", e.target.value)}
                placeholder="Name"
                className="textArea"
              ></TextArea>
              <TextArea
                autoSize
                value={area}
                onChange={(e) => this.handleChange("area", e.target.value)}
                placeholder="Area"
                className="textArea"
              ></TextArea>
              <TextArea
                autoSize
                value={mobile}
                onChange={(e) => this.handleChange("mobile", e.target.value)}
                maxLength={10}
                placeholder="Mobile Number"
                className="textArea"
              ></TextArea>

              <Button onClick={this.addPoc} className="addBtn">
                {isEdit ? "Save" : "Add"}
              </Button>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar isMobile={isMobile} />
          <div className="container">
            <p className="user">User Profile</p>
            <div className="Details">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operatorDashBoard/home/profilePhoto.svg"
                alt="profilePhoto"
              />
              <div>
                <div className="userDetail">
                  <span className="Bold">Hi {userName}</span>
                </div>
                <div className="userDetail">
                  <span className="Bold">Mobile Number : </span>
                  <span className="light">{userMobileNo}</span>
                </div>
                <div className="userDetail">
                  <span className="Bold">Email Id : </span>
                  <span className="light">{profile.email}</span>
                </div>
              </div>
            </div>
            <div className="PocHeader">
              <p className="user">POC Details</p>
              <div
                className="PocBtn"
                onClick={() => this.setState({ showPocModal: true })}
              >
                <p className="Poctxt">+ Add POC</p>
              </div>
            </div>
            <PocDetails
              editPoc={this.editPoc}
              details={profile.pocList}
              isMobile={isMobile}
            />
            <Modal
              open={showPocModal}
              onCancel={() =>
                this.setState({
                  showPocModal: false,
                  name: "",
                  mobile: null,
                  area: "",
                })
              }
              footer={null}
            >
              <div className="POCModal">
                <p className="modalHeader">Add POC</p>
                <p className="subHeader">Please enter the details </p>
                <TextArea
                  autoSize
                  value={name}
                  onChange={(e) => this.handleChange("name", e.target.value)}
                  placeholder="Name"
                  className="textArea"
                ></TextArea>
                <TextArea
                  autoSize
                  value={area}
                  onChange={(e) => this.handleChange("area", e.target.value)}
                  placeholder="Area"
                  className="textArea"
                ></TextArea>
                <TextArea
                  autoSize
                  value={mobile}
                  onChange={(e) => this.handleChange("mobile", e.target.value)}
                  maxLength={10}
                  placeholder="mobileNo"
                  className="textArea"
                ></TextArea>
                <Select
                  placeholder="Select Department"
                  allowClear
                  value={department}
                  options={departments}
                  onChange={(value) => this.handleChange("department", value)}
                />

                <Button onClick={this.addPoc} className="addBtn">
                  Add
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

export default Profile;
