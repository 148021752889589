import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./tripSearchFiltersDweb.scss";
import { format } from "date-fns";
import { calendarTimeToUnixInMills } from "../../utils/common";
import { Fetch } from "../../utils/fetch";

class TripSearchFiltersDweb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCity: "",
      fromCityId: "",
      toCity: "",
      toCityId: "",
      tripId: "",
      fromDate: null,
      toDate: null,
      showCalendar: false,
      calendarTarget: null,
      fromCities: [],
      toCities: [],
      isFromSelect: false,
      isToSelect: false,
      searchQuery: "",
    };
  }

  async componentDidMount() {
    this.setState({
      fromCity: this.props.fromCityFilter ? this.props.fromCityFilter : "",
      toCity: this.props.toCityFilter ? this.props.toCityFilter : "",
      fromDate: this.props.fromDateFilter ? this.props.fromDateFilter : "",
      toDate: this.props.toDateFilter ? this.props.toDateFilter : "",
      tripId: this.props.tripIdFilter ? this.props.tripIdFilter : "",
    });
  }

  onCitySelect = (city) => {
    const { isFromSelect, isToSelect } = this.state;
    if (isFromSelect) {
      this.setState({ fromCity: city.name, fromCityId: city._id });
    } else if (isToSelect) {
      this.setState({ toCity: city.name, toCityId: city._id });
    }
    this.setState({
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
      searchQuery: "",
    });
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleCitySearch = async (event, cityType) => {
    this.setState({ searchQuery: event.target.value });

    const path = `/cities/stations?type=${event.target.value}`;
    const options = {
      method: "get",
    };
    let citesSuggestions = await Fetch(path, options);
    // citesSuggestions = citesSuggestions.map((item) => item.name);
    if (cityType === "toCity") {
      this.setState({ toCities: [...citesSuggestions] });
    } else {
      this.setState({ fromCities: [...citesSuggestions] });
    }
  };

  swapCities = () => {
    this.setState((prevState) => ({
      fromCity: prevState.toCity,
      toCity: prevState.fromCity,
      fromCityId: prevState.toCityId,
      toCityId: prevState.fromCityId,
    }));
  };

  setFilters = async () => {
    const { fromCity, toCity, tripId, fromDate, toDate, fromCityId, toCityId } =
      this.state;
    const { applySearchFilters } = this.props;
    const searchFiltersApplied = {
      fromCity: fromCity,
      toCity: toCity,
      fromDate: fromDate,
      toDate: toDate,
      tripId: tripId,
      fromCityId: fromCityId,
      toCityId: toCityId,
    };
    await applySearchFilters(searchFiltersApplied);

    // this.props.setFromCityToCity(fromCity, toCity);
    // this.props.setTripIdFilter(tripId);
    // this.props.setDateFilters(fromDate, toDate);
  };

  handleDateChange = (date) => {
    const { calendarTarget } = this.state;
    let timestamp = calendarTimeToUnixInMills(date); // Convert date to Unix timestamp

    // If selecting "toDate", adjust time to 23:59:59
    if (calendarTarget === "toDate") {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
      timestamp = calendarTimeToUnixInMills(endOfDay);
    }

    this.setState(
      {
        [calendarTarget]: timestamp,
        showCalendar: false, // Close the calendar after selection
      },
      () => {
        // If 'fromDate' is selected, open 'toDate' calendar afterward
        if (calendarTarget === "fromDate") {
          this.setState({ calendarTarget: "toDate", showCalendar: false });
        }
      }
    );
  };

  formatDate = (date) => {
    return date ? format(date, "d MMM yyyy") : "Select Date";
  };

  openCalendar = (target) => {
    this.setState({
      showCalendar: true,
      calendarTarget: target,
    });
  };

  resetFilters = () => {
    this.setState({
      fromCity: "",
      toCity: "",
      fromCityId: "",
      toCityId: "",
      tripId: "",
      fromDate: null,
      toDate: null,
      showCitySelection: false,
    });
    this.props.resetAllSearchFilters();
  };

  render() {
    const {
      fromCity,
      toCity,
      fromCities,
      toCities,
      tripId,
      fromDate,
      toDate,
      showCalendar,
      calendarTarget,
      isFromSelect,
      isToSelect,
      showCitySelection,
      searchQuery,
    } = this.state;

    return (
      <div className="tripSearchFiltersDweb">
        <div className="filterGroup">
          {/* From City Input */}
          <div className="dropdownContainer">
            <input
              type="text"
              value={
                fromCity
                  ? fromCity
                  : isFromSelect && searchQuery
                  ? searchQuery
                  : ""
              }
              placeholder="From City"
              className="cityInput"
              onClick={() =>
                this.setState({
                  showCitySelection: true,
                  isFromSelect: true,
                  fromCity: "",
                  isToSelect: false,
                  searchQuery: "",
                })
              }
              onChange={(e) => this.handleCitySearch(e, "fromCity")}
            />
            {showCitySelection && isFromSelect && (
              <ul className="dropdown">
                {fromCities
                  .filter((city) =>
                    city.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((city, index) => (
                    <li key={index} onClick={() => this.onCitySelect(city)}>
                      {city.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>

          {/* Swap Icon */}
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/swapiconhoriz.svg"
            alt="Swap"
            className="swapIcon"
            onClick={this.swapCities}
          />

          {/* To City Input */}
          <div className="dropdownContainer">
            <input
              type="text"
              value={
                toCity ? toCity : isToSelect && searchQuery ? searchQuery : ""
              }
              placeholder="To City"
              className="cityInput"
              onClick={() =>
                this.setState({
                  showCitySelection: true,
                  isToSelect: true,
                  toCity: "",
                  isFromSelect: false,
                  searchQuery: "",
                })
              }
              onChange={(e) => this.handleCitySearch(e, "toCity")}
            />
            {showCitySelection && isToSelect && (
              <ul className="dropdown">
                {toCities
                  .filter((city) =>
                    city.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((city, index) => (
                    <li key={index} onClick={() => this.onCitySelect(city)}>
                      {city.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>

          <button className="searchButton" onClick={this.setFilters}>
            Search
          </button>
        </div>

        <div className="filterGroup">
          <input
            type="text"
            value={tripId}
            onChange={(e) => this.setState({ tripId: e.target.value })}
            placeholder="Search by Trip Id"
            className="tripIdInput"
          />
          <button className="searchButton" onClick={this.setFilters}>
            Search
          </button>
        </div>

        <div className="filterGroup">
          <div className="filter-field date-range">
            <img
              className="calendar-icon"
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/calendar-outline1.svg"
            />
            <div
              className="date-text"
              onClick={() => this.openCalendar("fromDate")}
            >
              {this.formatDate(fromDate)}
            </div>
            <span> - </span>
            <div
              className="date-text"
              onClick={() => this.openCalendar("toDate")}
            >
              {this.formatDate(toDate)}
            </div>
            {showCalendar && (
              <div className="tripPageCalendarModal">
                <DatePicker
                  selected={calendarTarget === "fromDate" ? fromDate : toDate}
                  onChange={this.handleDateChange}
                  inline
                  className="customDatepicker"
                  calendarClassName="centeredDatepicker"
                />
              </div>
            )}
          </div>
          <button className="searchButton" onClick={this.setFilters}>
            Search
          </button>
        </div>

        <button className="clearButton" onClick={this.resetFilters}>
          Reset
        </button>
      </div>
    );
  }
}

export default TripSearchFiltersDweb;
