import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Select,
  DatePicker,
  Modal,
  message,
  ConfigProvider,
  Button,
  Image,
} from "antd";
import Loader from "../../utils/loader";
import less from "./inspection.module.scss";
import cx from "classnames";
import { getHoursAndMinFromMillis } from "../../utils/common";
import moment from "moment";
import { AuditOptions, cloundFrontBaseUrl } from "../../config";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import InspectionDataTile from "../../components/inspectionDataTile/inspectionDataTile";
import InspectionDataMoreInfoTile from "../../components/InspectionDataMoreInfoTile";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "BUS RC",
    dataIndex: "rc",
  },
  {
    title: "Completion Time",
    dataIndex: "time",
    sorter: (a, b) => a.date.completionTime - b.date.completionTime,
    render: ({ completionTime }) => (
      <span>
        {completionTime ? getHoursAndMinFromMillis(completionTime) : null}
      </span>
    ),
  },
  {
    title: "Completion Date",
    dataIndex: "date",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.date.completionDate - b.date.completionDate,
    render: ({ completionDate }) => (
      <span>
        {completionDate ? moment(completionDate).format("DD-MMM-YY") : null}
      </span>
    ),
  },
  {
    title: "View Data",
    dataIndex: "view",
    render: ({ showMoreDetails, _id }) => {
      return (
        <div>
          <Button onClick={() => showMoreDetails({ _id })}>View</Button>
        </div>
      );
    },
  },
];
const moreDetailsColumns = [
  {
    title: "Serial",
    dataIndex: "serial",
  },
  {
    title: "Action Performed",
    dataIndex: "action",
  },
  {
    title: "Value",
    dataIndex: "value",
  },
  // {
  //   title: "Remarks",
  //   dataIndex: "remark",
  // },
  // {
  //   title: "Attachments",
  //   dataIndex: "attachment",
  //   render: ({ attachments, openAttachment }) => {
  //     if (attachments && attachments.length > 0) {
  //       return attachments.map((data, index) => (
  //         <div key={index} className={less.attachment}>
  //           <div
  //             onClick={() =>
  //               openAttachment(data.attachmentUrl, data.attachmentType)
  //             }
  //           >
  //             {data.attachmentType === "image" ? (
  //               <img
  //                 src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/image.svg"
  //                 alt="image"
  //               />
  //             ) : (
  //               <img
  //                 src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/video.svg"
  //                 alt="video"
  //               />
  //             )}
  //             <span>{data.attachmentLabel}</span>
  //           </div>
  //         </div>
  //       ));
  //     } else {
  //       return <b>No Attachment</b>;
  //     }
  //   },
  // },
];

class inspectionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tableLoader: false,
      isMobile: false,
      moreDetailsData: [],
      showMoreDetails: false,
      showAttachmentModal: false,
      busRcsOptions: [],
      auditTableData: [],
      auditSelected: "RegularAudit",
      showSideMenu: false,
      fromDate: null,
      toDate: null,
      moreDetailInspectionOptions: [],
    };
  }

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const stateObj = { loading: false, isMobile };
      const busRcsOptions = await this.fetchRc();
      const data = await this.fetchCampaigns({
        auditSelected: "RegularAudit",
        busRcsOptions,
      });
      stateObj.busRcsOptions = busRcsOptions;
      stateObj.auditTableData = data.auditTableData;
      stateObj.auditData = data.response;
      stateObj.auditName = data.auditName;

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchRc = async () => {
    const url = `/user/rc`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let busRcsOptions = [];
      response.forEach((data) => {
        let selectOpt = {
          value: data.rc,
          label: data.rc,
        };
        busRcsOptions.push(selectOpt);
      });
      return busRcsOptions;
    } else {
      message.error("something went wrong");
      return [];
    }
  };

  fetchCampaigns = async (data, clearDate) => {
    const { rc, auditSelected, fromDate, toDate, busRcsOptions } = this.state;
    let url = `/user/fetchAuditData?`;
    if (data.rc || rc) {
      let dataToBeAdded = data.rc ? data.rc : rc;
      url += `&rc=${dataToBeAdded}`;
    } else {
      let rcArray = data.busRcsOptions ? data.busRcsOptions : busRcsOptions;
      let rc = rcArray
        .map((obj) => {
          return obj.value;
        })
        .join(",");
      url += `&rc=${rc}`;
    }
    if (data.auditSelected || auditSelected) {
      let dataToBeAdded = data.auditSelected
        ? data.auditSelected
        : auditSelected;
      url += `&name=${dataToBeAdded}`;
    }
    if (!clearDate) {
      if (data.fromDate || fromDate) {
        let dataToBeAdded = data.fromDate ? data.fromDate : fromDate;
        url += `&fromDate=${dataToBeAdded}`;
      }
      if (data.toDate || toDate) {
        let dataToBeAdded = data.toDate ? data.toDate : toDate;
        url += `&toDate=${dataToBeAdded}`;
      }
    }
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let auditTableData;
      auditTableData = response.map((obj) => {
        return {
          rc: obj.busRc,
          time: { completionTime: obj.closedDate ? obj.closedDate : undefined },
          date: { completionDate: obj.closedDate ? obj.closedDate : undefined },
          view: {
            showMoreDetails: this.showMoreDetails,
            _id: obj._id,
          },
          key: obj._id,
        };
      });

      let auditName = AuditOptions.filter(
        (obj) => obj.value == auditSelected
      )[0].label;

      if (data.auditSelected) {
        auditName = AuditOptions.filter(
          (obj) => obj.value == data.auditSelected
        )[0].label;
      }

      let returnObj = {
        auditTableData,
        response,
        auditName,
      };
      return returnObj;
    } else {
      message.error("No Data Found");
      return [];
    }
  };

  showMoreDetails = (selectedId) => {
    const { auditData } = this.state;
    const auditMoreData = auditData.filter((obj) => obj._id == selectedId._id);
    if (auditMoreData.length > 0) {
      let count = 0;
      const moreDetailInspectionOptions = auditMoreData[0].groupWork.map(
        (item) => ({
          label: item.label,
          value: item.name,
        })
      );
      let workData = auditMoreData[0].groupWork.filter(
        (obj) => obj.name == moreDetailInspectionOptions[0].value
      )[0];
      let rc = auditMoreData[0].busRc;
      const moreDetailsData = workData.work.map((obj) => {
        count = count + 1;
        let data = {
          busRc: rc,
          serial: count,
          action: obj.name,
          value: obj.value,
          remark: obj.remark,
          attachment: {
            attachments: obj.attachments,
            openAttachment: this.showAttachment,
          },
        };
        return data;
      });
      this.setState({
        showMoreDetails: true,
        moreDetailInspectionOptions,
        moreDetailsData,
        selectedAuditId: selectedId._id,
      });
    } else {
      this.setState({ showMoreDetails: false });
    }
  };

  showAttachment = (attachmentUrl, type) => {
    const url = cloundFrontBaseUrl + attachmentUrl;
    const attachmentData = {
      attachmentUrl: url,
      attachmentType: type,
    };
    this.setState({
      showAttachmentModal: true,
      attachmentData,
    });
  };

  onFromDateChange = (date) => {
    let fromDate;
    if (date) {
      fromDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ fromDate });
  };

  onToDateChange = (date) => {
    let toDate;
    if (date) {
      toDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ toDate });
  };

  disabled7DaysDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 14;
    }
    return false;
  };

  onRangeChange = async (dates, dateStrings) => {
    if (dates) {
      const fromDate = moment(dateStrings[0], "DD/MM/YYYY").valueOf();
      const toDate = moment(dateStrings[1], "DD/MM/YYYY")
        .endOf("day")
        .valueOf();

      const obj = { fromDate, toDate };
      const data = await this.fetchCampaigns(obj);

      this.setState({
        fromDate,
        toDate,
        auditTableData: data.auditTableData,
        auditData: data.response,
        auditName: data.auditName,
      });
    } else {
      const obj = { fromDate: null, toDate: null };
      const data = await this.fetchCampaigns(obj, true);

      this.setState({
        fromDate: null,
        toDate: null,
        auditTableData: data.auditTableData,
        auditData: data.response,
        auditName: data.auditName,
      });
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  directSearh = async (key, value) => {
    const fetchData = { [key]: value };
    const data = await this.fetchCampaigns(fetchData);
    this.setState({
      [key]: value,
      auditTableData: data.auditTableData,
      auditData: data.response,
      auditName: data.auditName,
    });
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  showAnotherInspectionDetails = (inspectionId) => {
    const { auditData, selectedAuditId } = this.state;
    const auditMoreData = auditData.filter((obj) => obj._id == selectedAuditId);

    if (auditMoreData.length > 0) {
      let workData = auditMoreData[0].groupWork.filter(
        (obj) => obj.name == inspectionId
      )[0];
      if (!workData) {
        message.error("No Data For selected Inspection");
        return;
      }
      let count = 0;
      let rc = auditMoreData[0].busRc;
      const moreDetailsData = workData.work.map((obj) => {
        count = count + 1;
        let data = {
          busRc: rc,
          serial: count,
          action: obj.name,
          value: obj.value,
          remark: obj.remark,
          attachment: {
            attachments: obj.attachments,
            openAttachment: this.showAttachment,
          },
        };
        return data;
      });
      this.setState({
        showMoreDetails: true,
        moreDetailsData,
      });
    } else {
      this.setState({ showMoreDetails: false });
    }
  };

  disabledEndDate = (current) => {
    const { fromDate } = this.state;
    let startRangeDate =
      current && current < moment(fromDate).endOf("day").subtract(14, "d");
    let endRangeDate =
      current && current > moment(fromDate).endOf("day").add(14, "d");
    return !!startRangeDate || !!endRangeDate;
  };

  disabledStartDate = (current) => {
    return current && current > moment().endOf("day");
  };

  setStartDate = (e, string) => {
    const fromDate = moment(string, "DD/MM/YYYY").endOf("day").valueOf();
    this.setState({ fromDate });
  };

  setEndDate = async (e, string) => {
    const { fromDate } = this.state;
    const toDate = moment(string, "DD/MM/YYYY").endOf("day").valueOf();
    if (!fromDate) {
      message.error("Please select from Date first");
      return;
    }
    const obj = { fromDate, toDate };
    const data = await this.fetchCampaigns(obj);

    this.setState({
      fromDate,
      toDate,
      auditTableData: data.auditTableData,
      auditData: data.response,
      auditName: data.auditName,
    });
  };
  render() {
    const {
      loading,
      isMobile,
      tableLoader,
      auditTableData,
      moreDetailsData,
      busRcsOptions,
      showMoreDetails,
      attachmentData,
      showAttachmentModal,
      showSideMenu,
      moreDetailInspectionOptions,
    } = this.state;
    let active = 4;

    if (loading) {
      return (
        <div className={less.loader}>
          <Loader />
        </div>
      );
    } else {
      return isMobile ? (
        <>
          <MobileMainHeader toggleSideMenu={this.toggleSideMenu} />

          {showSideMenu ? (
            <MenuSlideDrawer
              toggleSideMenu={this.toggleSideMenu}
              showSideMenu={showSideMenu}
              isMobile={isMobile}
            >
              <SideMenu
                active={active}
                toggleSideMenu={this.toggleSideMenu}
                isMobile={isMobile}
              />
            </MenuSlideDrawer>
          ) : null}
          <div style={{ paddingTop: "75px" }}>
            <div className={less.pageHeading}>
              {/* <img
                    src="https://d1flzashw70bti.cloudfront.net/original/images/operator/backarrow.svg"
                    alt="back arrow"
                    className={less.backImg}
                  /> */}
              <span className={less.pageHeadingText}>Audit Data</span>
            </div>
          </div>
          <div className="container">
            <div className={less.searchHeader}>
              <div>
                <div>
                  <DatePicker
                    style={{ width: `100%` }}
                    disabledDate={this.disabledStartDate}
                    inputReadOnly={true}
                    format="DD-MM-YYYY"
                    onChange={this.setStartDate}
                  />{" "}
                  <DatePicker
                    style={{ width: `100%` }}
                    disabledDate={this.disabledEndDate}
                    inputReadOnly={true}
                    format="DD-MM-YYYY"
                    onChange={this.setEndDate}
                  />
                </div>
              </div>
              <div style={{ height: "10px" }}></div>
              <div>
                {" "}
                <Select
                  style={{ width: "49%", marginRight: "4px" }}
                  placeholder="Select a RC"
                  onChange={(value) => this.directSearh("rc", value)}
                  options={busRcsOptions}
                />
                <Select
                  style={{ width: "49%" }}
                  defaultValue="Regular Audit"
                  placeholder="Select an Audit"
                  onChange={(value) => this.directSearh("auditSelected", value)}
                  options={AuditOptions}
                />
              </div>
            </div>
            <div className={less.inspectionDataContainer}>
              {!showMoreDetails && auditTableData && auditTableData.length ? (
                auditTableData.map((data, index) => (
                  <InspectionDataTile
                    key={index}
                    id={data.view}
                    busRc={data.rc}
                    completionTime={
                      data.time.completionTime
                        ? moment(data.time.completionTime).format("hh:mm A")
                        : undefined
                    }
                    completionDate={
                      data.time.completionTime
                        ? moment(data.date.completionDate).format("DD-MMM-YYYY")
                        : undefined
                    }
                    onButtonClick={this.showMoreDetails}
                  />
                ))
              ) : showMoreDetails &&
                moreDetailsData &&
                moreDetailsData.length ? (
                <>
                  <div className={less.selectInspection}>
                    <Select
                      style={{ width: "100%", height: "40px" }}
                      defaultValue="Inspection Data 1"
                      placeholder="Select an Inspection"
                      onChange={(value) =>
                        this.showAnotherInspectionDetails(value)
                      }
                      options={moreDetailInspectionOptions}
                    />
                  </div>
                  {moreDetailsData.map((data, index) => {
                    return data ? (
                      <div
                        style={{
                          width: "100%",

                          display: "flex",

                          alignItems: "center",

                          justifyContent: "center",
                        }}
                      >
                        <InspectionDataMoreInfoTile
                          key={index}
                          serial={data.serial}
                          action={data.action}
                          value={data.value}
                          remark={data.remark}
                          attachments={
                            data.attachment && data.attachment.attachments
                              ? data.attachment.attachments
                              : []
                          }
                          showAttachment={this.showAttachment}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              ) : null}
            </div>
          </div>
          {attachmentData ? (
            <Modal
              open={showAttachmentModal}
              footer={null}
              onCancel={() => this.setState({ showAttachmentModal: false })}
            >
              {attachmentData.attachmentType == "image" ? (
                <Image src={attachmentData.attachmentUrl} />
              ) : attachmentData.attachmentType == "video" ? (
                <video
                  width="320"
                  height="240"
                  controls
                  autoplay
                  muted
                  playsinline
                >
                  <source src={attachmentData.attachmentUrl}></source>
                </video>
              ) : (
                <audio controls>
                  <source src={attachmentData.attachmentUrl}></source>
                </audio>
              )}
            </Modal>
          ) : null}
        </>
      ) : (
        <div>
          <Navbar selectedKey={3} isMobile={isMobile} />
          <div className="container">
            <div className={cx("DealsHeader", less.amenitiesRow)}>
              <span className="dealSpan">audit Data</span>
            </div>
            <div className={less.searchHeader}>
              <div>
                {" "}
                <Select
                  style={{ width: "50%" }}
                  placeholder="Select a RC"
                  onChange={(value) => this.directSearh("rc", value)}
                  options={busRcsOptions}
                />
                <Select
                  style={{ width: "50%" }}
                  defaultValue="Regular Audit"
                  placeholder="Select an audit"
                  onChange={(value) => this.directSearh("auditSelected", value)}
                  options={AuditOptions}
                />
              </div>
              <div>
                <div>
                  <RangePicker
                    onChange={this.onRangeChange}
                    disabledDate={this.disabled7DaysDate}
                    format="DD/MM/YYYY"
                  />
                </div>
              </div>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7b2bff",
                },
              }}
            >
              <Table
                loading={tableLoader}
                columns={columns}
                bordered={true}
                scroll={{ x: "max-content", y: isMobile ? "70vh" : null }}
                dataSource={auditTableData}
                pagination={false}
              />
            </ConfigProvider>
          </div>

          <Modal
            closeIcon={null}
            width={"80%"}
            open={showMoreDetails}
            footer={null}
          >
            <div className={less.moreDetailsModal}>
              <div>
                <Select
                  style={{ width: "100%" }}
                  defaultValue={moreDetailInspectionOptions[0]}
                  placeholder="Select an Inspection"
                  onChange={(value) => this.showAnotherInspectionDetails(value)}
                  options={moreDetailInspectionOptions}
                />
                {moreDetailsData && moreDetailsData.length > 0 ? (
                  <p>{moreDetailsData[0].busRc}</p>
                ) : null}
              </div>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/inspection/close.svg"
                alt="close"
                onClick={() =>
                  this.setState({
                    showMoreDetails: false,
                    moreDetailsData: [],
                  })
                }
              />
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7b2bff",
                },
              }}
            >
              <Table
                columns={moreDetailsColumns}
                dataSource={moreDetailsData}
                pagination={false}
              ></Table>
            </ConfigProvider>
          </Modal>
          {attachmentData ? (
            <Modal
              open={showAttachmentModal}
              footer={null}
              onCancel={() => this.setState({ showAttachmentModal: false })}
            >
              {attachmentData.attachmentType == "image" ? (
                <Image src={attachmentData.attachmentUrl} />
              ) : attachmentData.attachmentType == "video" ? (
                <video
                  width="320"
                  height="240"
                  controls
                  autoplay
                  muted
                  playsinline
                >
                  <source src={attachmentData.attachmentUrl}></source>
                </video>
              ) : (
                <audio controls>
                  <source src={attachmentData.attachmentUrl}></source>
                </audio>
              )}
            </Modal>
          ) : null}
        </div>
      );
    }
  }
}

export default inspectionData;
