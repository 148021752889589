import React, { Component } from "react";
import { Redirect } from "react-router-dom";

const ProtectedComponentWrapper = (ComponentToProtect) => {
  return class ProtectedComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isValidToken: false,
        loading: true,
        redirect: false,
      };
    }

    async componentDidMount() {
      const token = localStorage.getItem("idToken");
      if (token && token !== "Server exception") {
        this.setState({ isValidToken: true, loading: false });
      } else {
        // Store the intended URL before redirecting
        const intendedUrl = `${this.props.location.pathname}${this.props.location.search}`;
        sessionStorage.setItem("redirectAfterLogin", intendedUrl);

        // Redirect to login
        this.setState({ loading: false, redirect: true });
      }
    }

    render() {
      const { loading, isValidToken, redirect } = this.state;
      const { location } = this.props;

      if (loading) {
        return null;
      }

      if (redirect) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: `${location.pathname}${location.search}` },
            }}
          />
        );
      }

      if (isValidToken) {
        return <ComponentToProtect {...this.props} />;
      }
    }
  };
};

export default ProtectedComponentWrapper;
