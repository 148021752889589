// CitySelectionOverlay.js
import React, { Component } from "react";
import "./searchCityOverlayMweb.scss";
import { Fetch } from "../../utils/fetch";

class SearchCityOverlayMweb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      cities: [],
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSelectCity = (city) => {
    const { onCitySelect, closeOverlay } = this.props;
    onCitySelect(city);
    closeOverlay();
  };

  handleCitySearch = async (event) => {
    this.setState({ searchQuery: event.target.value });

    const path = `/cities/stations?type=${event.target.value}`;
    const options = {
      method: "get",
    };
    let citesSuggestions = await Fetch(path, options);
    this.setState({ cities: [...citesSuggestions] });
  };

  render() {
    const { closeOverlay } = this.props;
    const { searchQuery, cities } = this.state;

    // Filter cities based on the search query
    const filteredCities = cities.filter((city) =>
      city.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="searchCityOverlayContainer">
        <div className="overlay-header">
          <input
            type="text"
            placeholder="Search city"
            value={searchQuery}
            onChange={(e) => this.handleCitySearch(e, "fromCity")}
            className="search-input"
            ref={this.inputRef}
          />
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/opdash/sidemenu/closecross.svg"
            alt="close"
            className="close-button"
            onClick={closeOverlay}
          />
        </div>
        <div className="city-list">
          {filteredCities.map((city, index) => (
            <div
              className="city-item"
              key={index}
              onClick={() => this.handleSelectCity(city)}
            >
              <span className="city-name">{city.name}</span>
              <span className="select-text">Select</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SearchCityOverlayMweb;
