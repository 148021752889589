import React from "react";
import "./tripDetailsTile.scss";
import moment from "moment";

class TripDetailsTile extends React.Component {
  formatDate = (startTime) => {
    const date = moment(startTime);
    const formattedDate = date.format("DD MMM");
    return formattedDate;
  };

  formatTime = (startTime) => {
    const date = moment(startTime);
    const formattedDate = date.format("h:mm A");
    return formattedDate;
  };

  render() {
    const { tripData, index } = this.props;
    const { groupCode, startTime, from, to, seater } = tripData;

    return (
      <div
        className="tripDetailsTile"
        style={index === 0 ? { marginTop: "45px" } : null}
      >
        <div className="tripHeader">
          <span className="tripId">ID: {groupCode}</span>
          <div className="tripDateAndTime">
            <span className="tripDate">{this.formatDate(startTime)}</span>
            <span className="tripTime">{this.formatTime(startTime)}</span>
          </div>
        </div>
        <div className="tripRoute">
          <span className="routeFromTo">
            <strong>{from}</strong>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/opd/tiles/blackarrowright.svg"
              alt="Arrow Icon"
              className="arrowIcon"
            />
            <strong>{to}</strong>
          </span>
        </div>
        <div className="tripDetails">
          <span className="seater">Asset Type</span>
          <span className="seater">{seater}</span>
        </div>
      </div>
    );
  }
}

export default TripDetailsTile;
