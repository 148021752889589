import React, { Component } from "react";
import MobileMainHeader from "../../components/headers/mobileMainHeader";
import { MenuSlideDrawer } from "../../components/slideDrawer/SlideDrawer";
import SideMenu from "../../components/Layout/SideMenu";
import Loader from "../../utils/loader";
import { withRouter } from "react-router-dom";
import TripDetailsTile from "../../components/tiles/tripDetailsTile";
import MarshalDetailsTile from "../../components/tiles/marshalDetailsTile";
import "./tripDetails.scss";
import PassengerDetailsTile from "../../components/tiles/passengerDetailsTile";
import BottomDrawer from "../../components/Layout/bottomDrawer";
import AssignDriverForm from "../../components/assignDriverForm/assignDriverForm";
import MidScreenPopUp from "../../components/Layout/midScreenPopUp";
import { Fetch } from "../../utils/fetch";
import MarshalDetailsMweb from "../../components/marshalDetailsMweb";

class TripDetails extends Component {
  state = {
    loading: true,
    isMobile: false,
    showSideMenu: false,
    isBottomDrawerOpen: false,
    showPopUp: false,
    rcNumber: "",
    driverMobileNo: "",
    driverName: "",
    isDriverAssigned: false,
    assignedTripDetails: {},
    showMarshalDetails: false,
  };

  async componentDidMount() {
    try {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobile = regex.test(navigator.userAgent);
      const params = new URLSearchParams(this.props.location.search);
      const tripId = params.get("_id");

      const path = `/cabOperator/trip/details?tripId=${tripId}`;
      const options = {
        method: "get",
      };

      const assignedTripDetails = await Fetch(path, options);
      // Check if assignedTripDetails is not an array and show pop-up
      if (assignedTripDetails === "error") {
        this.setState({
          loading: false,
          showPopUp: true,
          assignedTripDetails: assignedTripDetails,
          isMobile: isMobile,
        });
      } else {
        this.setState({
          loading: false,
          isMobile: isMobile,
          rcNumber: assignedTripDetails[0].rcNumber,
          driverMobileNo: assignedTripDetails[0].driverMobileNo,
          driverName: assignedTripDetails[0].driverName,
          assignedTripDetails: assignedTripDetails[0],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  fetchConnectingMarshal = async (id) => {
    const url = `/cabOperator/fetchConnectingMarshal?id=${id}`;
    const options = {
      method: "get",
    };
    const response = await Fetch(url, options);
    return response;
  };

  toggleSideMenu = () => {
    const { showSideMenu } = this.state;
    this.setState({
      showSideMenu: !showSideMenu,
    });
  };

  toggleBottomDrawer = (assignedDriverDetails) => {
    this.setState((prevState) => ({
      isBottomDrawerOpen: !prevState.isBottomDrawerOpen,
    }));
    if (assignedDriverDetails) {
      this.setState({
        rcNumber: assignedDriverDetails.vehicleDetails.rc,
        driverMobileNo: assignedDriverDetails.driverDetails.mobileNo,
        driverName: assignedDriverDetails.driverDetails.name,
      });
    }
  };

  handleViewMarshalDetails = async () => {
    const params = new URLSearchParams(this.props.location.search);
    const tripId = params.get("_id");
    let connectingMarshal = {};
    if (tripId) {
      connectingMarshal = await this.fetchConnectingMarshal(tripId);
    }
    this.setState({
      connectingMarshal: connectingMarshal,
      showMarshalDetails: true,
    });
  };

  closeMarshalDetails = () => {
    this.setState({
      showMarshalDetails: false,
    });
  };

  togglePopUp = (isSuccessFul) => {
    this.setState((prevState) => ({
      showPopUp: !prevState.showPopUp,
      isDriverAssigned: isSuccessFul,
    }));

    // Redirect to "/trips" if pop-up was shown due to invalid trip details
    if (this.state.assignedTripDetails === "error") {
      this.props.history.push("/trips");
    }
  };

  render() {
    const {
      showSideMenu,
      isMobile,
      loading,
      isBottomDrawerOpen,
      showPopUp,
      rcNumber,
      driverMobileNo,
      driverName,
      isDriverAssigned,
      connectingMarshal,
      assignedTripDetails,
      showMarshalDetails,
    } = this.state;
    let active = 6;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    }

    return isMobile && assignedTripDetails !== "error" ? (
      <div className="container">
        <MobileMainHeader
          toggleSideMenu={this.toggleSideMenu}
          hasSubHeader={true}
        />
        {showMarshalDetails ? (
          <BottomDrawer
            heading="Marshal Details"
            isOpen={showMarshalDetails}
            onClose={this.closeMarshalDetails}
          >
            <MarshalDetailsMweb connectingMarshal={connectingMarshal} />
          </BottomDrawer>
        ) : null}

        {showSideMenu ? (
          <MenuSlideDrawer
            toggleSideMenu={this.toggleSideMenu}
            showSideMenu={showSideMenu}
            isMobile={isMobile}
          >
            <SideMenu active={active} toggleSideMenu={this.toggleSideMenu} />
          </MenuSlideDrawer>
        ) : null}
        <TripDetailsTile index={0} tripData={assignedTripDetails} />
        <MarshalDetailsTile
          handleViewMarshalDetails={this.handleViewMarshalDetails}
        />

        {driverMobileNo && driverName && rcNumber ? (
          <div className="driverDetailsContainer">
            <div className="assignmentStatus">
              <div className="status">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/greentick.svg"
                  alt="driver assigned"
                />
                <span>Assigned</span>
              </div>
              {assignedTripDetails.startTime + 14400000 > Date.now() ? ( // 14400000: 4hours
                <div
                  className="editDetails"
                  onClick={() => this.toggleBottomDrawer()}
                >
                  <span>Edit Details</span>
                </div>
              ) : null}
            </div>
            <div className="driverDetails">
              <div className="info">
                <div className="text">
                  <span>{`RC: ${rcNumber}`}</span>
                </div>
                <div className="text">
                  <span>{`Mobile: ${driverMobileNo}`}</span>
                </div>
              </div>
              <div className="info">
                <div className="text">
                  <span>{`Name: ${driverName}`}</span>
                </div>
              </div>
            </div>
          </div>
        ) : assignedTripDetails.startTime + 14400000 > Date.now() ? ( // 14400000: 4hrs
          <div className="assignDriverButton">
            <button onClick={() => this.toggleBottomDrawer()}>
              Assign Driver
            </button>
          </div>
        ) : null}

        {assignedTripDetails.bookings &&
        assignedTripDetails.bookings.length > 0 &&
        assignedTripDetails.bookings.some(
          (booking) => booking.status === "CONFIRMED"
        ) ? (
          <div className="label">
            <span className="text">Passenger Details</span>
          </div>
        ) : null}
        {assignedTripDetails.bookings.map((booking) =>
          booking.status === "CONFIRMED" ? (
            <PassengerDetailsTile
              key={booking._id}
              bookingData={booking}
              startTime={assignedTripDetails.startTime}
            />
          ) : null
        )}
        {isBottomDrawerOpen ? (
          <BottomDrawer
            heading="Assign a driver"
            isOpen={isBottomDrawerOpen}
            onClose={this.toggleBottomDrawer}
          >
            <AssignDriverForm
              tripData={assignedTripDetails}
              onCloseDrawer={this.toggleBottomDrawer}
              togglePopUp={this.togglePopUp}
              driverMobileNo={driverMobileNo}
              driverName={driverName}
              rcNumber={rcNumber}
            />
          </BottomDrawer>
        ) : null}

        {showPopUp && isDriverAssigned ? (
          <MidScreenPopUp
            imageUrl="https://d1flzashw70bti.cloudfront.net/original/images/optdash/popup/greentick.svg"
            text="Assignment Successful"
            subText="Trip has been successfully assigned to driver."
            buttonText="Ok"
            onClose={this.togglePopUp}
          />
        ) : showPopUp && !isDriverAssigned ? (
          <MidScreenPopUp
            imageUrl="https://d1flzashw70bti.cloudfront.net/original/images/optdash/crisis.svg"
            text="Assignment Failed"
            subText="Trip could not be assigned to driver."
            buttonText="Ok"
            onClose={this.togglePopUp}
          />
        ) : null}
      </div>
    ) : showPopUp && assignedTripDetails === "error" ? (
      <MidScreenPopUp
        imageUrl="https://d1flzashw70bti.cloudfront.net/original/images/optdash/crisis.svg"
        text="Trip doesn’t exist"
        subText="This trip has been reassigned and is not available anymore"
        buttonText="Go to Home"
        onClose={this.togglePopUp}
      />
    ) : null;
  }
}

export default withRouter(TripDetails);
