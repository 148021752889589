import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/login";
import Deals from "./pages/deals";
import operatorDealsDetails from "./pages/operatorDealsDetails";
import offlineCommission from "./pages/offlineCommission";
import seatFare from "./pages/seatFare";
import payouts from "./pages/payouts";
import DealDetail from "./pages/dealDetails";
import Home from "./pages/home";
import Profile from "./pages/profile";
import inspectionData from "./pages/inspectionData";
import auditData from "./pages/auditData";
import feedbackData from "./pages/feedback";
import myBuses from "./pages/myBuses";
import serviceContracts from "./pages/serviceContracts";
import paymentSummary from "./pages/paymentSummary";
import tripsRevenue from "./pages/tripsRevenue";
import trips from "./pages/trips";
import tripDetails from "./pages/trips/tripDetails";
import NotFoundPage from "./pages/NotFoundPage";
import ProtectedComponentWrapper from "./components/HOC/protectedComponents";
import "./App.css";
import FmLmPaymentSummary from "./pages/fmlmPaymentSummary";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" exact component={ProtectedComponentWrapper(Home)} />
        <Route
          path="/profile"
          exact
          component={ProtectedComponentWrapper(Profile)}
        />
        <Route path="/deals" component={ProtectedComponentWrapper(Deals)} />
        <Route
          path="/offlineCommission"
          exact
          component={ProtectedComponentWrapper(offlineCommission)}
        />
        <Route
          path="/seatFare"
          exact
          component={ProtectedComponentWrapper(seatFare)}
        />
        <Route
          path="/payouts"
          exact
          component={ProtectedComponentWrapper(payouts)}
        />
        <Route
          path="/inspectionDataDetails"
          exact
          component={ProtectedComponentWrapper(inspectionData)}
        />
        <Route
          path="/auditDataDetails"
          exact
          component={ProtectedComponentWrapper(auditData)}
        />
        <Route
          path="/feedbackDetails"
          exact
          component={ProtectedComponentWrapper(feedbackData)}
        />
        <Route
          path="/myBuses"
          exact
          component={ProtectedComponentWrapper(myBuses)}
        />
        <Route
          path="/serviceContracts"
          exact
          component={ProtectedComponentWrapper(serviceContracts)}
        />
        <Route
          path="/paymentSummary"
          exact
          component={ProtectedComponentWrapper(paymentSummary)}
        />
        <Route
          path="/tripsRevenue"
          exact
          component={ProtectedComponentWrapper(tripsRevenue)}
        />
        <Route
          path="/trips"
          exact
          component={ProtectedComponentWrapper(trips)}
        />
        <Route
          path="/trips/tripDetails"
          exact
          component={ProtectedComponentWrapper(tripDetails)}
        />

        <Route
          path="/operatorDealsDetails"
          exact
          component={ProtectedComponentWrapper(operatorDealsDetails)}
        />
        <Route
          path="/operatorDealsDetails/:campaignId"
          component={ProtectedComponentWrapper(DealDetail)}
        />
        <Route
          path="/fmlmPaymentSummary"
          exact
          component={ProtectedComponentWrapper(FmLmPaymentSummary)}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
