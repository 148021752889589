import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateSearchBarMweb.scss";
import { format } from "date-fns";
import { calendarTimeToUnixInMills } from "../../utils/common";

class DateSearchBarMweb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      showCalendar: false,
      calendarTarget: null,
    };
  }

  async componentDidMount() {
    this.setState({
      fromDate: this.props.fromDateFilter ? this.props.fromDateFilter : "",
      toDate: this.props.toDateFilter ? this.props.toDateFilter : "",
    });
  }

  handleDateChange = async (date) => {
    const { calendarTarget } = this.state;
    let timestamp = calendarTimeToUnixInMills(date); // Convert date to Unix timestamp

    // If selecting "toDate", adjust time to 23:59:59
    if (calendarTarget === "toDate") {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
      timestamp = calendarTimeToUnixInMills(endOfDay);
    }

    this.setState(
      {
        [calendarTarget]: timestamp,
        showCalendar: false, // Close the calendar after selection
      },
      () => {
        // If 'fromDate' is selected, open 'toDate' calendar afterward
        if (calendarTarget === "fromDate") {
          this.setState({ calendarTarget: "toDate", showCalendar: false });
        }
      }
    );

    if (calendarTarget === "fromDate" && timestamp) {
      await this.props.applyDateFilter(timestamp, this.state.toDate);
    } else if (calendarTarget === "toDate" && timestamp) {
      await this.props.applyDateFilter(this.state.fromDate, timestamp);
    }
  };

  openCalendar = (target) => {
    this.setState({
      showCalendar: true,
      calendarTarget: target,
    });
  };

  formatDate = (date) => {
    return date ? format(date, "d MMM yyyy") : "Select Date";
  };

  render() {
    const { fromDate, toDate, showCalendar, calendarTarget } = this.state;

    return (
      <div className="dateSearchBarContainer">
        <div className="wrapper">
          <div className="label">
            <span>Showing results for </span>
          </div>
          <div className="filter-field date-range">
            <div
              className="date-text"
              onClick={() => this.openCalendar("fromDate")}
            >
              {this.formatDate(fromDate)}
            </div>
            <span> - </span>
            <div
              className="date-text"
              onClick={() => this.openCalendar("toDate")}
            >
              {this.formatDate(toDate)}
            </div>
            <img
              className="calendar-icon"
              src="https://d1flzashw70bti.cloudfront.net/original/images/ptdash/subheader/calendar-outline.svg"
            />

            {showCalendar && (
              <div className="calendar-modal">
                <DatePicker
                  selected={calendarTarget === "fromDate" ? fromDate : toDate}
                  onChange={this.handleDateChange}
                  inline
                  className="custom-datepicker"
                  calendarClassName="centered-datepicker"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DateSearchBarMweb;
