import React, { Component } from "react";
import jwt from "jsonwebtoken";
import { formatDate, formatTime } from "../../utils/common";
import "./tripTable.scss";
import { Fetch } from "../../utils/fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUpModal from "../Layout/popUpModal";
import AssignDriverForm from "../assignDriverForm/assignDriverForm";
import MidScreenPopUp from "../Layout/midScreenPopUp";

class TripTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
      showPopUp: false,
      rcNumber: "",
      driverMobileNo: "",
      driverName: "",
      showAssignDriverPopUp: false,
      isDriverAssigned: false,
      assignedTripDetails: {},
      tooltipVisible: false,
      tooltipPosition: { top: 0, left: 0 },
      tooltipData: {},
      marshalDetails: {},
      disableViewDetails: {}, // Track button disable state for each trip
      noMarshalDetails: {}, // Track trips where marshal details are unavailable
    };
  }

  componentDidMount() {
    toast.configure();
  }

  toggleRow = (id) => {
    this.setState((prevState) => ({
      expandedRow: prevState.expandedRow === id ? null : id,
    }));
  };

  handleTooltipHover = (event, data) => {
    const { top, left, height } = event.target.getBoundingClientRect();

    this.setState({
      tooltipVisible: true,
      tooltipPosition: {
        top: top + height + 10 + window.scrollY,
        left: left - 240,
      },
      tooltipData: data,
    });
  };

  handleTooltipClick = (event, data) => {
    const { top, left, height } = event.target.getBoundingClientRect();

    this.setState((prevState) => ({
      tooltipVisible: !prevState.tooltipVisible,
      tooltipPosition: {
        top: top + height + 10 + window.scrollY,
        left: left - 240,
      },
      tooltipData: data,
    }));
  };

  hideTooltip = () => {
    this.setState({ tooltipVisible: false });
  };
  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  handleDial = async (mobileNo) => {
    if (this.state.isDialDisabled) return;

    this.setState({ isDialDisabled: true });
    setTimeout(() => {
      this.setState({ isDialDisabled: false });
    }, 5000);

    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const userMobileNo = decodedToken.mobileNo;

    const url = "/cabOperator/clickToCall";
    const options = {
      method: "post",
      data: {
        caller: userMobileNo,
        receiver: mobileNo,
      },
    };

    const response = await Fetch(url, options);
    if (response === "call success") {
      toast.success(
        "Call initiated successfully! You will receive a connecting call soon"
      );
    }
  };

  openAssignDriverPopUp = async (assignedDriverDetails, index) => {
    if (index !== undefined && index !== null && index >= 0) {
      const { trips } = this.props;
      this.setState({
        assignedTripDetails: trips[index],
        rcNumber: trips[index].rcNumber,
        driverMobileNo: trips[index].driverMobileNo,
        driverName: trips[index].driverName,
      });
    }
    this.setState((prevState) => ({
      showAssignDriverPopUp: !prevState.showAssignDriverPopUp,
    }));
    if (assignedDriverDetails) {
      this.setState({
        rcNumber: assignedDriverDetails.vehicleDetails.rc,
        driverMobileNo: assignedDriverDetails.driverDetails.mobileNo,
        driverName: assignedDriverDetails.driverDetails.name,
      });
    }
  };

  togglePopUp = async (isSuccessFul) => {
    this.setState((prevState) => ({
      showPopUp: !prevState.showPopUp,
      isDriverAssigned: isSuccessFul,
    }));
    if (isSuccessFul) {
      await this.props.refreshTripsData();
    }
  };

  handleViewDetailsClick = (id) => {
    this.fetchConnectingMarshal(id); // Call API function

    // Temporarily disable the button
    this.setState((prevState) => ({
      disableViewDetails: { ...prevState.disableViewDetails, [id]: true },
    }));

    // Re-enable after 3 seconds
    setTimeout(() => {
      this.setState((prevState) => ({
        disableViewDetails: { ...prevState.disableViewDetails, [id]: false },
      }));
    }, 3000);
  };

  fetchConnectingMarshal = async (id) => {
    try {
      const url = `/cabOperator/fetchConnectingMarshal?id=${id}`;
      const options = {
        method: "get",
      };
      const response = await Fetch(url, options);
      if (response && response._id) {
        const { marshalDetails } = this.state;
        const obj = { ...marshalDetails };
        obj[id] = {
          name: response.name,
          mobileNo: response.mobileNo,
        };
        this.setState({
          marshalDetails: obj,
        });
      } else {
        // If no data received, mark trip as having no marshal details
        this.setState((prevState) => ({
          noMarshalDetails: { ...prevState.noMarshalDetails, [id]: true },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { trips } = this.props;
    const {
      expandedRow,
      showAssignDriverPopUp,
      driverMobileNo,
      driverName,
      rcNumber,
      assignedTripDetails,
      showPopUp,
      isDriverAssigned,
      tooltipData,
      tooltipVisible,
      marshalDetails,
      noMarshalDetails,
    } = this.state;

    return (
      <>
        {showAssignDriverPopUp ? (
          <PopUpModal
            isOpen={showAssignDriverPopUp}
            onClose={this.openAssignDriverPopUp}
            title="Assign Driver"
            width="400px"
          >
            <AssignDriverForm
              tripData={assignedTripDetails}
              onCloseDrawer={this.openAssignDriverPopUp}
              togglePopUp={this.togglePopUp}
              driverMobileNo={driverMobileNo}
              driverName={driverName}
              rcNumber={rcNumber}
            />
          </PopUpModal>
        ) : null}

        {showPopUp && isDriverAssigned ? (
          <MidScreenPopUp
            imageUrl="https://d1flzashw70bti.cloudfront.net/original/images/optdash/popup/greentick.svg"
            text="Assignment Successful"
            subText="Trip has been successfully assigned to driver."
            buttonText="Ok"
            onClose={this.togglePopUp}
          />
        ) : showPopUp && !isDriverAssigned ? (
          <MidScreenPopUp
            imageUrl="https://d1flzashw70bti.cloudfront.net/original/images/optdash/crisis.svg"
            text="Assignment Failed"
            subText="Trip could not be assigned to driver."
            buttonText="Ok"
            onClose={this.togglePopUp}
          />
        ) : null}

        {tooltipVisible && (
          <div
            className="tooltipBox"
            style={{
              position: "absolute",
              top: this.state.tooltipPosition.top,
              left: this.state.tooltipPosition.left,
            }}
          >
            <div className="tooltipRow">
              <span>Rc: {tooltipData.value1}</span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/whitecopyicon.svg"
                alt="copy"
                onClick={() => this.copyToClipboard(tooltipData.value1)}
              />
            </div>
            <div className="tooltipRow">
              <span>Mobile: {tooltipData.value2}</span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/whitecopyicon.svg"
                alt="copy"
                onClick={() => this.copyToClipboard(tooltipData.value2)}
              />
            </div>
            <div className="tooltipRow">
              <span>Name: {tooltipData.value3}</span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/whitecopyicon.svg"
                alt="copy"
                onClick={() => this.copyToClipboard(tooltipData.value3)}
              />
            </div>
          </div>
        )}

        <div className="tripTableContainer" onClick={() => this.hideTooltip()}>
          <table className="tripTable">
            <thead>
              <tr>
                <th>
                  <div>Route</div>
                </th>
                <th>
                  <div className="coloumnTitle">Trip ID</div>
                </th>
                <th>
                  <div className="coloumnTitle">Start Time & Date</div>
                </th>
                <th>
                  <div className="coloumnTitle">Bookings</div>
                </th>
                <th>
                  <div className="coloumnTitle">Marshal</div>
                </th>
                <th>
                  <div className="coloumnTitle">Asset Type</div>
                </th>
                <th>
                  <div className="coloumnTitle">Assign</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {trips.map((trip, index) => (
                <React.Fragment key={trip.id}>
                  <tr>
                    <td className="fromAndTo">{`${trip.from} - ${trip.to}`}</td>
                    <td className="tripId">
                      <span className="code">{trip.groupCode}</span>
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/copyicon.svg"
                        alt="copy"
                        className="copyIcon"
                        onClick={() => this.copyToClipboard(trip.groupCode)}
                      />
                    </td>
                    <td>
                      <div className="timeAndDate">
                        <span className="time">
                          {formatTime(trip.startTime, "h:mm A")}
                        </span>
                        <span className="date">
                          {formatDate(trip.startTime, "D MMM YYYY")}
                        </span>
                      </div>
                    </td>
                    <td className="details">
                      <span className="noOfBookingsText">
                        {
                          trip.bookings.filter(
                            (booking) => booking.status === "CONFIRMED"
                          ).length
                        }
                      </span>
                      <button
                        className="detailsBtn"
                        onClick={() => this.toggleRow(trip.id)}
                      >
                        Details{" "}
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/caret-down1.svg"
                          alt="dropdown"
                          style={
                            expandedRow === trip.id
                              ? { transform: "rotate(180deg)" }
                              : null
                          }
                        />
                      </button>
                    </td>
                    <td className="marshalInfo">
                      {marshalDetails[trip.id] ? (
                        <div className="info">
                          <strong>{marshalDetails[trip.id].name}</strong>
                          <span>{marshalDetails[trip.id].mobileNo}</span>
                        </div>
                      ) : noMarshalDetails[trip.id] ? (
                        <div className="info">N.A</div>
                      ) : (
                        <div
                          className={`viewDetails ${
                            this.state.disableViewDetails[trip.id]
                              ? "disabled"
                              : ""
                          }`}
                          onClick={
                            !this.state.disableViewDetails[trip.id]
                              ? () => this.handleViewDetailsClick(trip.id)
                              : null
                          }
                          style={{
                            pointerEvents: this.state.disableViewDetails[
                              trip.id
                            ]
                              ? "none"
                              : "auto",
                            opacity: this.state.disableViewDetails[trip.id]
                              ? 0.5
                              : 1,
                          }}
                        >
                          View Details
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="cellConainer">{trip.seater || "-"}</div>
                    </td>
                    <td>
                      {trip.driverMobileNo ? (
                        <div className="assigned">
                          <div className="textWrapper">
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/greenTick.svg"
                              alt="assigned"
                              className="greenTick"
                            />
                            <span>Assigned</span>
                            <img
                              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/infoicon.svg"
                              alt="assigned info"
                              className="toolTipIcon"
                              onMouseEnter={(e) =>
                                this.handleTooltipHover(e, {
                                  value1: trip.rcNumber,
                                  value2: trip.driverMobileNo,
                                  value3: trip.driverName,
                                })
                              }
                              onClick={(e) =>
                                this.handleTooltipClick(e, {
                                  value1: trip.rcNumber,
                                  value2: trip.driverMobileNo,
                                  value3: trip.driverName,
                                })
                              }
                            />
                          </div>
                          {trip.startTime + 14400000 > Date.now() ? (
                            <div
                              className="editView"
                              onClick={() =>
                                this.openAssignDriverPopUp(null, index)
                              }
                            >
                              Edit/View Details
                            </div>
                          ) : null}
                        </div>
                      ) : trip.startTime + 14400000 > Date.now() ? (
                        <div className="cellConainer">
                          <button
                            className="assignBtn"
                            onClick={() =>
                              this.openAssignDriverPopUp(null, index)
                            }
                          >
                            Assign
                          </button>
                        </div>
                      ) : (
                        <div className="cellConainer">
                          <span>N.A</span>
                        </div>
                      )}
                    </td>
                  </tr>
                  {expandedRow === trip.id && (
                    <tr className="expandedRow">
                      <td colSpan="7">
                        <div className="bookingTiles">
                          {trip.bookings
                            .filter((booking) => booking.status === "CONFIRMED")
                            .map((booking, index) => (
                              <div className="bookingTile" key={booking._id}>
                                <div className="index">
                                  <span>{index + 1}</span>
                                </div>
                                <div className="name">
                                  <span>{booking.name}</span>
                                </div>
                                <div className="seats">
                                  Seats: <strong>{booking.noOfSeats}</strong>
                                </div>
                                <div className="pickupAndDrop">
                                  Pickup Station:{" "}
                                  <strong>{booking.fromStation.address}</strong>
                                  <br />
                                  <div style={{ marginTop: "8px" }}></div>
                                  Drop Station:{" "}
                                  <strong>{booking.toStation.address}</strong>
                                </div>
                                <div className="pickupAndDropTime">
                                  Pickup Time:{" "}
                                  <strong>
                                    {formatTime(
                                      booking.fromStationDate,
                                      "h:mm A"
                                    )}
                                  </strong>
                                  <br />
                                  <div style={{ marginTop: "8px" }}></div>
                                  Drop Time:{" "}
                                  <strong>
                                    {" "}
                                    {formatTime(
                                      booking.toStationDate,
                                      "h:mm A"
                                    )}
                                  </strong>
                                </div>
                                <div className="callBtnContainer">
                                  {trip.startTime + 14400000 > Date.now() ? (
                                    <div
                                      className="callBtn"
                                      onClick={() =>
                                        this.handleDial(booking.mobileNo)
                                      }
                                    >
                                      <img
                                        src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/callIcon.svg"
                                        alt="call"
                                      />
                                      Call
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default TripTable;
