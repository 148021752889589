// src/pages/NotFoundPage.js

import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <p>Not Found</p>
    </div>
  );
};

export default NotFoundPage;
