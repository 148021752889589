import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fmlmPaymentSummarySearchForm.scss";
import { format } from "date-fns";
import { calendarTimeToUnixInMills } from "../../utils/common";
import { routeTypes } from "../../config";
import { Fetch } from "../../utils/fetch";

class FmlmPaymentSummarySearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCity: "",
      fromCityId: "",
      toCity: "",
      toCityId: "",
      tripId: "",
      fromDate: null,
      toDate: null,
      showCalendar: false,
      calendarTarget: null,
      fromCities: [],
      toCities: [],
      isFromSelect: false,
      isToSelect: false,
      searchQuery: "",
      routeType: "",
      isRouteTypeSelect: false,
      showRouteTypeSelection: false,
      routeTypeOptions: routeTypes,
      assetType: "",
      showAssetTypeSelection: false,
      isAssetTypeSelect: false,
      assetTypeOptions: [],
    };
  }

  async componentDidMount() {
    this.setState({
      fromCity: this.props.fromCityFilter ? this.props.fromCityFilter : "",
      toCity: this.props.toCityFilter ? this.props.toCityFilter : "",
      fromDate: this.props.fromDateFilter ? this.props.fromDateFilter : "",
      toDate: this.props.toDateFilter ? this.props.toDateFilter : "",
      routeType: this.props.routeTypeFilter ? this.props.routeTypeFilter : "",
      assetType: this.props.assetTypeFilter ? this.props.assetTypeFilter : "",
      assetTypeOptions: this.props.assetTypes,
    });
  }

  onCitySelect = (city) => {
    const { isFromSelect, isToSelect } = this.state;
    if (isFromSelect) {
      this.setState({ fromCity: city.name, fromCityId: city._id });
    } else if (isToSelect) {
      this.setState({ toCity: city.name, toCityId: city._id });
    }
    this.setState({
      showCitySelection: false,
      isFromSelect: false,
      isToSelect: false,
      searchQuery: "",
    });
  };

  onRouteTypeSelect = (routeType) => {
    this.setState({
      routeType: routeType,
      showRouteTypeSelection: false,
      isRouteTypeSelect: false,
      searchQuery: "",
    });
  };

  onAssetTypeSelect = (assetType) => {
    this.setState({
      assetType: assetType,
      showAssetTypeSelection: false,
      isAssetTypeSelect: false,
      searchQuery: "",
    });
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleCitySearch = async (event, cityType) => {
    this.setState({ searchQuery: event.target.value });

    const path = `/cities/stations?type=${event.target.value}`;
    const options = {
      method: "get",
    };
    let citesSuggestions = await Fetch(path, options);
    // citesSuggestions = citesSuggestions.map((item) => item.name);
    if (cityType === "toCity") {
      this.setState({ toCities: [...citesSuggestions] });
    } else {
      this.setState({ fromCities: [...citesSuggestions] });
    }
  };

  swapCities = () => {
    this.setState((prevState) => ({
      fromCity: prevState.toCity,
      toCity: prevState.fromCity,
      fromCityId: prevState.toCityId,
      toCityId: prevState.fromCityId,
    }));
  };

  setFilters = async () => {
    const {
      fromCity,
      toCity,
      fromDate,
      toDate,
      routeType,
      assetType,
      fromCityId,
      toCityId,
    } = this.state;
    const searchFiltersApplied = {
      fromCity: fromCity,
      toCity: toCity,
      fromDate: fromDate,
      toDate: toDate,
      routeType: routeType,
      assetType: assetType,
      fromCityId: fromCityId,
      toCityId: toCityId,
    };
    await this.props.applySearchFiltersDweb(searchFiltersApplied);
  };

  handleDateChange = (date) => {
    const { calendarTarget } = this.state;
    let timestamp = calendarTimeToUnixInMills(date); // Convert date to Unix timestamp

    // If selecting "toDate", adjust time to 23:59:59
    if (calendarTarget === "toDate") {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
      timestamp = calendarTimeToUnixInMills(endOfDay);
    }

    this.setState(
      {
        [calendarTarget]: timestamp,
        showCalendar: false, // Close the calendar after selection
      },
      () => {
        // If 'fromDate' is selected, open 'toDate' calendar afterward
        if (calendarTarget === "fromDate") {
          this.setState({ calendarTarget: "toDate", showCalendar: false });
        }
      }
    );
  };

  formatDate = (date) => {
    return date ? format(date, "d MMM yyyy") : "Select Date";
  };

  openCalendar = (target) => {
    this.setState({
      showCalendar: true,
      calendarTarget: target,
    });
  };

  resetFilters = () => {
    const { fmlmPaymentSummarySearch } = this.props;
    this.setState({
      fromCity: "",
      toCity: "",
      fromDate: fmlmPaymentSummarySearch.fromDate,
      toDate: fmlmPaymentSummarySearch.toDate,
      showCitySelection: false,
      fromCityId: "",
      toCityId: "",
      routeType: "",
      assetType: "",
    });
    this.props.resetAllSearchFilters();
  };

  render() {
    const {
      fromCity,
      toCity,
      fromCities,
      toCities,
      fromDate,
      toDate,
      showCalendar,
      calendarTarget,
      isFromSelect,
      isToSelect,
      showCitySelection,
      searchQuery,
      routeType,
      routeTypeOptions,
      isRouteTypeSelect,
      showRouteTypeSelection,
      assetType,
      isAssetTypeSelect,
      showAssetTypeSelection,
      assetTypeOptions,
    } = this.state;

    return (
      <div className="fmlmSummarySearchFiltersDweb">
        <div className="filterGroup">
          <div className="filter-field date-range">
            <img
              className="calendar-icon"
              src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/calendar-outline1.svg"
            />
            <div
              className="date-text"
              onClick={() => this.openCalendar("fromDate")}
            >
              {this.formatDate(fromDate)}
            </div>
            <span> - </span>
            <div
              className="date-text"
              onClick={() => this.openCalendar("toDate")}
            >
              {this.formatDate(toDate)}
            </div>
            {showCalendar && (
              <div className="calendarModalPopUp">
                <DatePicker
                  selected={calendarTarget === "fromDate" ? fromDate : toDate}
                  onChange={this.handleDateChange}
                  inline
                  className="customDatepicker"
                  calendarClassName="centeredDatepicker"
                />
              </div>
            )}
          </div>
        </div>

        <div className="filterGroup">
          {/* From City Input */}
          <div className="dropdownContainer">
            <input
              type="text"
              value={
                fromCity
                  ? fromCity
                  : isFromSelect && searchQuery
                  ? searchQuery
                  : ""
              }
              placeholder="From City"
              className="cityInput"
              onClick={() =>
                this.setState({
                  showCitySelection: true,
                  isFromSelect: true,
                  fromCity: "",
                  isToSelect: false,
                  isRouteTypeSelect: false,
                  isAssetTypeSelect: false,
                  searchQuery: "",
                })
              }
              onChange={(e) => this.handleCitySearch(e, "fromCity")}
            />
            {showCitySelection && isFromSelect && (
              <ul className="dropdown">
                {fromCities
                  .filter((city) =>
                    city.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((city, index) => (
                    <li key={index} onClick={() => this.onCitySelect(city)}>
                      {city.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>

          {/* Swap Icon */}
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/swapiconhoriz.svg"
            alt="Swap"
            className="swapIcon"
            onClick={this.swapCities}
          />

          {/* To City Input */}
          <div className="dropdownContainer">
            <input
              type="text"
              value={
                toCity ? toCity : isToSelect && searchQuery ? searchQuery : ""
              }
              placeholder="To City"
              className="cityInput"
              onClick={() =>
                this.setState({
                  showCitySelection: true,
                  isToSelect: true,
                  toCity: "",
                  isFromSelect: false,
                  isRouteTypeSelect: false,
                  isAssetTypeSelect: false,
                  searchQuery: "",
                })
              }
              onChange={(e) => this.handleCitySearch(e, "toCity")}
            />
            {showCitySelection && isToSelect && (
              <ul className="dropdown">
                {toCities
                  .filter((city) =>
                    city.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((city, index) => (
                    <li key={index} onClick={() => this.onCitySelect(city)}>
                      {city.name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {/* Route Type Input */}
        <div className="filterGroup">
          <div className="dropdownSelectContainer">
            <div className="inputWrapper">
              <input
                type="text"
                value={
                  routeType
                    ? routeType
                    : isRouteTypeSelect && searchQuery
                    ? searchQuery
                    : ""
                }
                placeholder="Search by route type"
                className="routeType"
                onClick={() =>
                  this.setState({
                    showRouteTypeSelection: true,
                    isRouteTypeSelect: true,
                    routeType: "",
                    isFromSelect: false,
                    isToSelect: false,
                    isAssetTypeSelect: false,
                    searchQuery: "",
                  })
                }
                onChange={this.handleSearchChange}
              />
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/searchbar/dropdownIcon.svg"
                alt="Dropdown Icon"
                className="dropdownIcon"
                onClick={() =>
                  this.setState((prevState) => ({
                    showRouteTypeSelection: !prevState.showRouteTypeSelection,
                    isRouteTypeSelect: !prevState.isRouteTypeSelect,
                    isFromSelect: false,
                    isToSelect: false,
                    isAssetTypeSelect: false,
                    searchQuery: "",
                  }))
                }
              />
            </div>
            {showRouteTypeSelection && isRouteTypeSelect && (
              <ul className="selectDropdown">
                {routeTypeOptions
                  .filter((type) =>
                    type.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((type, index) => (
                    <li
                      key={index}
                      onClick={() => this.onRouteTypeSelect(type)}
                    >
                      {type}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {/* Asset Type Type Input */}
        <div className="filterGroup">
          <div className="dropdownSelectContainer">
            <div className="inputWrapper">
              <input
                type="text"
                value={
                  assetType
                    ? assetType
                    : isAssetTypeSelect && searchQuery
                    ? searchQuery
                    : ""
                }
                placeholder="Search by asset type"
                className="routeType"
                onClick={() =>
                  this.setState({
                    showAssetTypeSelection: true,
                    isAssetTypeSelect: true,
                    assetType: "",
                    isFromSelect: false,
                    isToSelect: false,
                    isRouteTypeSelect: false,
                    searchQuery: "",
                  })
                }
                onChange={this.handleSearchChange}
              />
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/searchbar/dropdownIcon.svg"
                alt="Dropdown Icon"
                className="dropdownIcon"
                onClick={() =>
                  this.setState((prevState) => ({
                    showAssetTypeSelection: !prevState.showAssetTypeSelection,
                    isAssetTypeSelect: !prevState.isAssetTypeSelect,
                    isFromSelect: false,
                    isToSelect: false,
                    isRouteTypeSelect: false,
                    searchQuery: "",
                  }))
                }
              />
            </div>
            {showAssetTypeSelection && isAssetTypeSelect && (
              <ul className="selectDropdown">
                {assetTypeOptions
                  .filter((assetType) =>
                    assetType.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((assetType, index) => (
                    <li
                      key={index}
                      onClick={() => this.onAssetTypeSelect(assetType)}
                    >
                      {assetType}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
        <button className="searchButton" onClick={this.setFilters}>
          Search
        </button>

        <button className="clearButton" onClick={this.resetFilters}>
          Reset
        </button>
      </div>
    );
  }
}

export default FmlmPaymentSummarySearchForm;
