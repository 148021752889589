import React, { Component } from "react";
import "./earningsView.scss";

class EarningsView extends Component {
  render() {
    const { totalEarnings, avgTripEarning, highestTripEarning, totalTrips } =
      this.props;
    return (
      <div className="earningsView">
        <div className="card">
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/earningsview/commute.svg"
            alt="Total Trips"
            className="icon"
          />
          <div className="textContainer">
            <span className="title">Total Trips</span>
            <span className="amount">{totalTrips}</span>
          </div>
        </div>
        <div className="card">
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/earningsview/walleticon.svg"
            alt="Total Earnings"
            className="icon"
          />
          <div className="textContainer">
            <span className="title">Total Earnings</span>
            <span className="amount">{`₹${totalEarnings}`}</span>
          </div>
        </div>
        <div className="card">
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/earningsview/currency_rupee_circle.svg"
            alt="Avg/Trip"
            className="icon"
          />
          <div className="textContainer">
            <span className="title">Avg/Trip</span>
            <span className="amount">{`₹${avgTripEarning}`}</span>
          </div>
        </div>
        <div className="card">
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/earningsview/trophy.svg"
            alt="Highest Trip"
            className="icon"
          />
          <div className="textContainer">
            <span className="title">Highest Trip</span>
            <span className="amount">{`₹${highestTripEarning}`}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default EarningsView;
