const devServerURL = "https://operator.zingbus.com/v1";
export let baseURL = devServerURL;

export const defaultSearchDateRanges = {
  fmlmPaymentSummarySearch: {
    fromDate: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
      0,
      0,
      0,
      0
    ),
    toDate: new Date().setHours(23, 59, 59, 999),
  },
  fmlmTripSearch: {
    fromDate: new Date().getTime() - 12 * 60 * 60 * 1000,
    toDate: new Date().getTime() + 12 * 60 * 60 * 1000,
  },
};
export const routeTypes = ["Inter", "Intra"];
export const inspectionOptions = [
  {
    value: "INSPECTIONONE",
    label: "Inspection Data 1",
  },
  {
    value: "INSPECTIONTWO",
    label: "Inspection Data 2",
  },
  {
    value: "INSPECTIONTHREE",
    label: "Inspection Data 3",
  },
  {
    value: "INSPECTIONFOUR",
    label: "Inspection Data 4",
  },
  {
    value: "INSPECTIONFIVE",
    label: "Inspection Data 5",
  },
];

export const AuditOptions = [
  {
    value: "RegularAudit",
    label: "Regular Audit",
  },
  {
    value: "OnboardingAudit",
    label: "Onboarding Audit",
  },
  {
    value: "TripStart Audit",
    label: "TripStart Audit",
  },
];

export const cloundFrontBaseUrl = "https://d370edwo3kkakj.cloudfront.net/";

export const operatorType = {
  CABOWNER: "CABOWNER",
  BUSOWNER: "BUSOWNER",
};
export const capchaKey = "6LfXVU8eAAAAABOhUt2yjj313QfNUmlWn7PyX4bk";

