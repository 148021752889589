import React, { Component } from "react";
import { Fetch } from "../../utils/fetch";
import Navbar from "../../components/newNavBar";
import {
  Table,
  Tooltip,
  DatePicker,
  Modal,
  Input,
  message,
  ConfigProvider,
  AutoComplete,
} from "antd";
import { getDaysArray, getHoursAndMinFromMillis } from "../../utils/common";
import Loader from "../../utils/loader";
import less from "./seatFare.module.scss";
import cx from "classnames";
import moment from "moment";

const { TextArea } = Input;
const TooltipStyle = {
  color: "white",
  inner: {
    fontFamily: "Poppins",
    color: "black",
  },
};

class seatFare extends Component {
  state = {
    loading: true,
    tableLoader: false,
    isMobile: false,
    cityNames: [],
    tableData: [],
    columns: [],
    selectedRowKeys: [],
    updateBulkService: false,
    singleUpdateModal: false,
  };

  async componentDidMount() {
    try {
      const stateObj = { loading: false };
      const services = await this.fetchServices();
      if (services && services.allCities) {
        stateObj.cityNames = services.allCities;
      }
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      stateObj.isMobile = regex.test(navigator.userAgent);
      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchServices = async () => {
    const url = `/commission/services`;

    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);
    if (!Array.isArray(response.services)) {
      message.error("Something went wrong");
    }
    if (response.services) {
      this.formatTable(response.services);
    }
    return response;
  };

  formatTable = (services) => {
    const columns = [
      {
        title: "Service Name",
        dataIndex: "name",
      },
      {
        title: "Start Time",
        dataIndex: "time",
        sorter: (a, b) => a.time.startTime - b.time.startTime,
        render: ({ startTime }) => (
          <span>{getHoursAndMinFromMillis(startTime)}</span>
        ),
      },
      {
        title: "Bus Type",
        dataIndex: "busAsset",
      },
      // {
      //   title: "Seat limit",
      //   dataIndex: "limit",
      //   sorter: (a, b) => a.limit.seatLimit - b.limit.seatLimit,
      //   render: ({ seatLimit, serviceId }) => (
      //     <div className={less.tableDiv}>
      //       <span>{seatLimit}</span>
      //       <img
      //         src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
      //         alt="edit"
      //         onClick={() =>
      //           this.handleDataChange(
      //             "Seatlimit",
      //             undefined,
      //             serviceId,
      //             undefined,
      //             seatLimit
      //           )
      //         }
      //       />
      //     </div>
      //   ),
      // },
      {
        title: "Seat Type",
        dataIndex: "time",
        render: () => (
          <div className={less.tableType}>
            <p>seater</p>
            <p>sleeper</p>
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div className={less.tableTitle}>
              <span>FallBack Fare</span>
              <Tooltip
                title="The fallback fare is like a safety net—it kicks in when the standard fare is missing or inaccessible. So, if an agent has booked seats for a date, and the usual fare data isn't there, the system automatically applies the fallback fare."
                trigger="hover"
                placement="right"
                color={TooltipStyle.color}
                overlayInnerStyle={TooltipStyle.inner}
              >
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                  alt="info"
                />
              </Tooltip>
            </div>
          );
        },
        dataIndex: "fallback",
        render: ({ seater, sleeper, serviceId }) => (
          <>
            <div className={less.tableDiv}>
              <p>{seater}</p>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
                alt="edit"
                onClick={() =>
                  this.handleDataChange(
                    "fallback",
                    "seater",
                    serviceId,
                    undefined,
                    seater
                  )
                }
              />
            </div>
            <div className={less.tableDiv}>
              <p>{sleeper}</p>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
                alt="edit"
                onClick={() =>
                  this.handleDataChange(
                    "fallback",
                    "sleeper",
                    serviceId,
                    undefined,
                    sleeper
                  )
                }
              />
            </div>
          </>
        ),
      },
    ];

    const tableData = services.map((service) => {
      return {
        name: service.fromCityName + " to " + service.toCityName,
        time: { startTime: service.startTime },
        limit: { seatLimit: service.seatLimit, serviceId: service._id },
        key: service._id,
        busAsset: service.b2cAssetTypeName,
        fallback: {
          serviceId: service._id,
          seater: service.fallbackFlatFare
            ? service.fallbackFlatFare.seater
            : undefined,
          sleeper: service.fallbackFlatFare
            ? service.fallbackFlatFare.sleeper
            : undefined,
        },
      };
    });

    this.setState({ columns, tableData });
  };

  fetchFilteredServices = async () => {
    this.setState({ tableLoader: true });
    const { fromCitySelected, toCitySelected } = this.state;
    let url = `/commission/services`;
    if (
      (fromCitySelected && !toCitySelected) ||
      (toCitySelected && !fromCitySelected)
    ) {
      message.error("Please select both from and to City");
    }
    if (fromCitySelected) {
      url += `?fromCitySelected=${fromCitySelected}`;
    }
    if (toCitySelected) {
      url += `&toCitySelected=${toCitySelected}`;
    }
    const options = {
      method: "get",
    };

    const response = await Fetch(url, options);
    if (!Array.isArray(response.services)) {
      message.error("Not Found");
      this.setState({ tableLoader: false });
      return;
    }
    if (this.state.fromDate && this.state.toDate) {
      this.formatFilteredTable(response.services);
    } else {
      this.formatTable(response.services);
    }
    this.setState({ tableLoader: false, selectedRowKeys: [] });
  };

  onFromDateChange = (date) => {
    let fromDate;
    if (date) {
      fromDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ fromDate });
  };

  onToDateChange = (date) => {
    let toDate;
    if (date) {
      toDate = moment(new Date(date)).format("YYYY-MM-DD");
    }
    this.setState({ toDate });
  };

  disableToDate = (current) => {
    const { fromDate } = this.state;
    let startRangeDate = current && current < moment(fromDate).endOf("day");
    let endRangeDate =
      current && current > moment(fromDate).endOf("day").add(14, "d");
    return !!startRangeDate || !!endRangeDate;
  };

  disableFromDate = (current) => {
    return current && current < moment().endOf("day").subtract(1, "d");
  };

  formatFilteredTable = (services) => {
    let { fromDate, toDate } = this.state;
    const columns = [
      {
        title: "Service Name",
        dataIndex: "name",
      },
      {
        title: "Start Time",
        dataIndex: "time",
        sorter: (a, b) => a.time.startTime - b.time.startTime,
        render: ({ startTime }) => (
          <span>{getHoursAndMinFromMillis(startTime)}</span>
        ),
      },
      {
        title: "Bus Type",
        dataIndex: "busAsset",
      },
      // {
      //   title: "Seat limit",
      //   dataIndex: "limit",
      //   sorter: (a, b) => a.limit.seatLimit - b.limit.seatLimit,
      //   render: ({ seatLimit, serviceId }) => (
      //     <div className={less.tableDiv}>
      //       <span>{seatLimit}</span>
      //       <img
      //         src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
      //         alt="edit"
      //         onClick={() =>
      //           this.handleDataChange(
      //             "Seatlimit",
      //             undefined,
      //             serviceId,
      //             undefined,
      //             seatLimit
      //           )
      //         }
      //       />
      //     </div>
      //   ),
      // },
      {
        title: "Seat Type",
        dataIndex: "time",
        render: () => (
          <div className={less.tableType}>
            <p>seater</p>
            <p>sleeper</p>
          </div>
        ),
      },
    ];

    const daylist = getDaysArray(new Date(fromDate), new Date(toDate));
    const selectedDates = daylist.map((v) => v);
    for (let i = 0; i < selectedDates.length; i++) {
      let obj = selectedDates[i];
      let data = {
        title: obj,
        dataIndex: obj,
        render: ({ seater, sleeper, serviceId }) => (
          <>
            <div className={less.tableDiv}>
              <p>{seater}</p>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
                alt="edit"
                onClick={() =>
                  this.handleDataChange(
                    "date",
                    "seater",
                    serviceId,
                    obj,
                    seater
                  )
                }
              />
            </div>
            <div className={less.tableDiv}>
              <p>{sleeper}</p>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/edit.svg"
                alt="edit"
                onClick={() =>
                  this.handleDataChange(
                    "date",
                    "sleeper",
                    serviceId,
                    obj,
                    sleeper
                  )
                }
              />
            </div>
          </>
        ),
      };
      columns.push(data);
    }
    const tableData = services.map((service) => {
      const sDates = selectedDates.map((obj) => {
        return {
          [obj]: {
            seater:
              service.tripDateWiseData &&
              service.tripDateWiseData[obj] &&
              service.tripDateWiseData[obj].flatFare &&
              service.tripDateWiseData[obj].flatFare.seater
                ? service.tripDateWiseData[obj].flatFare.seater
                : undefined,
            sleeper:
              service.tripDateWiseData &&
              service.tripDateWiseData[obj] &&
              service.tripDateWiseData[obj].flatFare &&
              service.tripDateWiseData[obj].flatFare.sleeper
                ? service.tripDateWiseData[obj].flatFare.sleeper
                : undefined,
            serviceId: service._id,
          },
        };
      });
      let dates = {};
      for (let i = 0; i < sDates.length; i++) {
        Object.assign(dates, sDates[i]);
      }
      return {
        name: service.fromCityName + " to " + service.toCityName,
        time: { startTime: service.startTime },
        limit: { seatLimit: service.seatLimit, serviceId: service._id },
        key: service._id,
        busAsset: service.b2cAssetTypeName,
        ...dates,
      };
    });

    this.setState({ columns, tableData });
  };

  handleChange = (key, value) => {
    const re = /^[0-9\b]+$/;
    if (value.length < 5 && (value === "" || re.test(value))) {
      this.setState({ [key]: value });
    }
  };

  handleDataChange = async (
    modalType,
    seatType,
    serviceId,
    dateSelected,
    value
  ) => {
    let stateObj = {};
    if (seatType == "sleeper") {
      stateObj.seaterUpdate = value;
    }
    if (seatType == "seater") {
      stateObj.sleeperUpdate = value;
    }
    if (modalType == "fallback") {
      stateObj.modalType = modalType;
      stateObj.seatType = seatType;
      stateObj.selectedServiceId = serviceId;
      stateObj.fallbackUpdate = value;
      stateObj.singleUpdateModal = true;
    } else if (modalType == "Seatlimit") {
      stateObj.modalType = modalType;
      stateObj.selectedServiceId = serviceId;
      stateObj.singleUpdateModal = true;
      stateObj.seatLimitUpdate = value;
    } else {
      //date
      stateObj.modalType = modalType;
      stateObj.seatType = seatType;
      stateObj.selectedServiceId = serviceId;
      stateObj.dateSelected = dateSelected;
      stateObj.dateSeatFare = value;
      stateObj.singleUpdateModal = true;
    }
    this.setState(stateObj);
  };

  singleUpdate = async () => {
    const {
      fromCitySelected,
      toCitySelected,
      selectedServiceId,
      seatLimitUpdate,
      fallbackUpdate,
      tableData,
      seatType,
      dateSelected,
      dateSeatFare,
    } = this.state;
    if (
      seatLimitUpdate &&
      (parseInt(seatLimitUpdate) > 100 || parseInt(seatLimitUpdate) < 5)
    ) {
      message.error("Seat Limit Should Be In Between 5 to 100");
      return;
    }

    if (
      fallbackUpdate &&
      (parseInt(fallbackUpdate) < 50 || parseInt(fallbackUpdate) > 3000)
    ) {
      message.error("Fallback Should Be In Between 50 to 3000");
      return;
    }

    let updateObj = {
      _id: selectedServiceId,
    };
    if (seatLimitUpdate) {
      updateObj.seatLimit = parseInt(seatLimitUpdate);
    }

    if (fallbackUpdate) {
      const filteredData = tableData.filter(
        (obj) => obj.key == selectedServiceId
      );

      if (seatType == "seater") {
        updateObj.fallbackFlatFare = {
          sleeper:
            filteredData[0].fallback && filteredData[0].fallback.sleeper
              ? filteredData[0].fallback.sleeper
              : undefined,
          seater: parseInt(fallbackUpdate),
        };
      } else {
        updateObj.fallbackFlatFare = {
          sleeper: parseInt(fallbackUpdate),
          seater:
            filteredData[0].fallback && filteredData[0].fallback.seater
              ? filteredData[0].fallback.seater
              : undefined,
        };
      }
    }
    if (dateSelected) {
      const filteredData = tableData.filter(
        (obj) => obj.key == selectedServiceId
      );
      updateObj.tripDateWiseData = {};
      updateObj.tripDateWiseData[dateSelected] = {};

      if (seatType == "seater") {
        updateObj.tripDateWiseData[dateSelected].flatFare = {
          sleeper:
            filteredData[0][dateSelected] &&
            filteredData[0][dateSelected].sleeper
              ? filteredData[0][dateSelected].sleeper
              : undefined,
          seater: parseInt(dateSeatFare),
        };
      } else {
        updateObj.tripDateWiseData[dateSelected].flatFare = {
          sleeper: parseInt(dateSeatFare),
          seater:
            filteredData[0][dateSelected] &&
            filteredData[0][dateSelected].seater
              ? filteredData[0][dateSelected].seater
              : undefined,
        };
      }
    }

    const url = `/commission/services/update`;

    const obj = {
      updateObj: [updateObj],
      fromCitySelected,
      toCitySelected,
    };
    const options = {
      method: "post",
      data: obj,
    };

    const response = await Fetch(url, options);

    if (!response.length) {
      message.error("Something Went Wrong");
      return;
    } else {
      if (dateSelected) {
        this.formatFilteredTable(response);
      } else {
        this.formatTable(response);
      }
      message.success("Values Updated");

      this.setState({
        selectedServiceId: undefined,
        seatLimitUpdate: undefined,
        singleUpdateModal: false,
        fallbackUpdate: undefined,
        dateSeatFare: undefined,
        seatType: undefined,
      });
    }
  };

  bulkUpdate = async () => {
    const {
      fromCitySelected,
      toCitySelected,
      selectedRowKeys,
      fromDate,
      toDate,
      tableData,
      seatLimitUpdate,
      seaterUpdate,
      sleeperUpdate,
    } = this.state;
    if (
      seatLimitUpdate &&
      (parseInt(seatLimitUpdate) > 100 || parseInt(seatLimitUpdate) < 5)
    ) {
      message.error("Seat Limit Should Be In Between 5 to 100");
      return;
    }
    if (
      seaterUpdate &&
      (parseInt(seaterUpdate) < 50 || parseInt(seaterUpdate) > 3000)
    ) {
      message.error("Fare Should Be In Between 50 to 3000");
      return;
    }
    if (
      sleeperUpdate &&
      (parseInt(sleeperUpdate) < 50 || parseInt(sleeperUpdate) > 3000)
    ) {
      message.error("Fare Should Be In Between 50 to 3000");
      return;
    }

    const services = tableData.filter((service) => {
      return selectedRowKeys.some((id) => {
        return id === service.key;
      });
    });
    let updateObj = services.map((data) => {
      return {
        _id: data.key,
        seatLimit: seatLimitUpdate ? parseInt(seatLimitUpdate) : undefined,
        fallbackFlatFare: {
          seater: seaterUpdate ? parseInt(seaterUpdate) : data.fallback.seater,
          sleeper: sleeperUpdate
            ? parseInt(sleeperUpdate)
            : data.fallback.sleeper,
        },
      };
    });
    if (fromDate && toDate) {
      const daylist = getDaysArray(new Date(fromDate), new Date(toDate));
      const selectedDates = daylist.map((v) => v);
      updateObj = services.map((data) => {
        let tripDateWiseData = {};
        if (seaterUpdate || sleeperUpdate) {
          const sDates = selectedDates.map((obj) => {
            return {
              [obj]: {
                flatFare: {
                  seater: seaterUpdate
                    ? parseInt(seaterUpdate)
                    : data.tripDateWiseData[obj].flatFare.seater,
                  sleeper: sleeperUpdate
                    ? parseInt(sleeperUpdate)
                    : data.tripDateWiseData[obj].flatFare.sleeper,
                },
              },
            };
          });

          for (let i = 0; i < sDates.length; i++) {
            Object.assign(tripDateWiseData, sDates[i]);
          }
        }
        return {
          _id: data.key,
          seatLimit: seatLimitUpdate ? parseInt(seatLimitUpdate) : undefined,
          tripDateWiseData:
            JSON.stringify(tripDateWiseData) != "{}"
              ? { ...tripDateWiseData }
              : undefined,
        };
      });
    }
    const url = `/commission/services/update`;
    let obj = {
      updateObj,
      fromCitySelected,
      toCitySelected,
    };
    const options = {
      method: "post",
      data: obj,
    };
    const response = await Fetch(url, options);
    if (!response.length) {
      message.error("Something Went Wrong");
      return;
    } else {
      message.success("Values Updated");
      if (fromDate && toDate) {
        this.formatFilteredTable(response);
      } else {
        this.formatTable(response);
      }
      this.setState({
        seatLimitUpdate: undefined,
        seaterUpdate: undefined,
        sleeperUpdate: undefined,
        updateBulkService: false,
      });
    }
  };

  showModal = (toShow) => {
    this.setState({ [toShow]: true });
  };

  render() {
    const {
      loading,
      isMobile,
      cityNames,
      tableLoader,
      columns,
      selectedRowKeys,
      tableData,
      singleUpdateModal,
      modalType,
      seatLimitUpdate,
      fallbackUpdate,
      seatType,
      dateSeatFare,
      updateBulkService,
      fromDate,
      toDate,
      sleeperUpdate,
      seaterUpdate,
    } = this.state;
    if (loading) {
      return (
        <div className="loader">
          <Loader />
        </div>
      );
    } else {
      return (
        <div>
          <Navbar selectedKey={4} isMobile={isMobile} />
          <div class="container">
            <div className={cx("DealsHeader", less.amenitiesRow)}>
              <span className="dealSpan">Offline Seat Fare</span>
              <div>
                <div
                  className={
                    selectedRowKeys.length > 0
                      ? less.bulkDiv
                      : less.InActivebulkDiv
                  }
                >
                  <span
                    onClick={() =>
                      selectedRowKeys.length > 0
                        ? this.showModal("updateBulkService")
                        : null
                    }
                  >
                    Bulk Update
                  </span>
                </div>
              </div>
            </div>
            <div className={less.searchHeader}>
              <div>
                <AutoComplete
                  placeholder="From City"
                  style={{
                    width: 200,
                  }}
                  options={cityNames}
                  onChange={(value) => {
                    let id = cityNames.filter(
                      (service) => service.value === value
                    );
                    if (id.length) {
                      this.setState({
                        fromCitySelected: id[0].value,
                      });
                    } else {
                      this.setState({
                        fromCitySelected: null,
                      });
                    }
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/change.svg"
                  alt="change"
                ></img>
                <AutoComplete
                  placeholder="To City"
                  style={{
                    width: 200,
                  }}
                  options={cityNames}
                  onChange={(value) => {
                    let id = cityNames.filter(
                      (service) => service.value === value
                    );
                    if (id.length) {
                      this.setState({
                        toCitySelected: id[0].value,
                      });
                    } else {
                      this.setState({
                        toCitySelected: null,
                      });
                    }
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
              <div>
                <span>From Date:</span>
                <DatePicker
                  format="DD-MM-YYYY"
                  disabledDate={this.disableFromDate}
                  onChange={this.onFromDateChange}
                />
                <span>To Date:</span>
                <DatePicker
                  disabledDate={this.disableToDate}
                  format="DD-MM-YYYY"
                  onChange={this.onToDateChange}
                />
              </div>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/operator/offlineCommission/search.svg"
                alt="search"
                onClick={this.fetchFilteredServices}
              />
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7b2bff",
                },
              }}
            >
              <Table
                loading={tableLoader}
                columns={columns}
                bordered={true}
                scroll={{ x: "max-content", y: isMobile ? "70vh" : null }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (keys) => {
                    this.setState({
                      selectedRowKeys: keys,
                    });
                  },
                }}
                dataSource={tableData}
                pagination={false}
              />
            </ConfigProvider>
            <Modal
              centered
              title="Update Parameters"
              open={singleUpdateModal}
              onCancel={() =>
                this.setState({
                  selectedServiceId: undefined,
                  seatLimitUpdate: undefined,
                  singleUpdateModal: false,
                  fallbackUpdate: undefined,
                  dateSeatFare: undefined,
                  seatType: undefined,
                })
              }
              footer={null}
            >
              {modalType == "fallback" ? (
                <>
                  <div className={less.commisiondiv}>
                    <span>{seatType} Fare Update</span>
                    <Tooltip
                      title="Ranges between 50-3000"
                      trigger="hover"
                      placement="right"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>

                  <TextArea
                    autoSize
                    allowClear
                    value={fallbackUpdate}
                    onChange={(e) =>
                      this.handleChange("fallbackUpdate", e.target.value)
                    }
                    placeholder="Enter Fare"
                    className={less.TextArea}
                  ></TextArea>
                  <div className={less.modalDateInfo}>
                    <span>Update Fallback Fare for {seatType} </span>
                  </div>
                </>
              ) : modalType == "Seatlimit" ? (
                <>
                  <div className={less.commisiondiv}>
                    <span>Seat Limit</span>
                    <Tooltip
                      title="Ranges between 5-100"
                      trigger="hover"
                      placement="right"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <TextArea
                    autoSize
                    allowClear
                    value={seatLimitUpdate}
                    onChange={(e) =>
                      this.handleChange("seatLimitUpdate", e.target.value)
                    }
                    placeholder="Enter Seat Limit"
                    className={less.TextArea}
                  ></TextArea>
                </>
              ) : (
                //date discount update
                <>
                  <div className={less.commisiondiv}>
                    <span>Fare Update</span>
                    <Tooltip
                      title="Ranges between 50-3000"
                      trigger="hover"
                      placement="right"
                      color={TooltipStyle.color}
                      overlayInnerStyle={TooltipStyle.inner}
                    >
                      <img
                        src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                        alt="info"
                      />
                    </Tooltip>
                  </div>
                  <TextArea
                    allowClear
                    autoSize
                    value={dateSeatFare}
                    onChange={(e) =>
                      this.handleChange("dateSeatFare", e.target.value)
                    }
                    placeholder="Enter Seat Fare"
                    className={less.TextArea}
                  ></TextArea>
                </>
              )}

              <div className={less.divButton}>
                <button
                  onClick={() =>
                    this.setState({
                      selectedServiceId: undefined,
                      seatLimitUpdate: undefined,
                      singleUpdateModal: false,
                      fallbackUpdate: undefined,
                      dateSeatFare: undefined,
                      seatType: undefined,
                    })
                  }
                >
                  Close
                </button>
                <button onClick={this.singleUpdate}>Save Updates</button>
              </div>
            </Modal>
            <Modal
              centered
              title="Update Parameters"
              open={updateBulkService}
              onCancel={() =>
                this.setState({
                  seatLimitUpdate: undefined,
                  seaterUpdate: undefined,
                  sleeperUpdate: undefined,
                  updateBulkService: false,
                })
              }
              footer={null}
            >
              <>
                <div className={less.commisiondiv}>
                  {fromDate && toDate ? (
                    <span>
                      For the selected dates, enter the updated values. If the
                      field is left blank, then the values will not be updated
                      for the trips
                    </span>
                  ) : (
                    <span>
                      For the selected services, enter the updated values. If
                      the field is left blank, then the values will not be
                      updated for the services
                    </span>
                  )}
                </div>

                <div className={less.bulkServiceUpdate}>
                  <div>
                    <div className={less.commisiondiv}>
                      <span>Sleeper Fare</span>
                      <Tooltip
                        title="Ranges between 50-3000"
                        trigger="hover"
                        placement="right"
                        color={TooltipStyle.color}
                        overlayInnerStyle={TooltipStyle.inner}
                      >
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                          alt="info"
                        />
                      </Tooltip>
                    </div>
                    <TextArea
                      autoSize
                      value={sleeperUpdate}
                      onChange={(e) =>
                        this.handleChange("sleeperUpdate", e.target.value)
                      }
                      placeholder="Enter Sleeper Fare"
                      className={less.TextArea}
                    ></TextArea>
                  </div>
                  <div>
                    <div className={less.commisiondiv}>
                      <span>Seater Fare</span>
                      <Tooltip
                        title="Ranges between 50-3000"
                        trigger="hover"
                        placement="right"
                        color={TooltipStyle.color}
                        overlayInnerStyle={TooltipStyle.inner}
                      >
                        <img
                          src="https://d1flzashw70bti.cloudfront.net/original/images/operator/deals/info.svg"
                          alt="info"
                        />
                      </Tooltip>
                    </div>
                    <TextArea
                      autoSize
                      value={seaterUpdate}
                      onChange={(e) =>
                        this.handleChange("seaterUpdate", e.target.value)
                      }
                      placeholder="Enter Seater Fare"
                      className={less.TextArea}
                    ></TextArea>
                  </div>
                </div>

                {fromDate && toDate ? (
                  <div className={less.modalDateInfo}>
                    <span>Date Range : </span>
                    <span>
                      {moment(fromDate).format("DD/MM/YYYY")} to{" "}
                      {moment(toDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                ) : (
                  <div className={less.modalDateInfo}>
                    <span>FallBack Fares are being updated </span>
                  </div>
                )}
              </>
              <div className={less.divButton}>
                <button
                  onClick={() =>
                    this.setState({
                      seatLimitUpdate: undefined,
                      seaterUpdate: undefined,
                      sleeperUpdate: undefined,
                      updateBulkService: false,
                    })
                  }
                >
                  Close
                </button>
                <button onClick={this.bulkUpdate}>Save Updates</button>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

export default seatFare;
