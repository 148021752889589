import React from "react";
import "./fmlmPaymentSummaryTile.scss";
import { formatDate, formatTime } from "../../utils/common";

class FmlmPaymentSummaryTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: false,
      tooltipPosition: { top: 0, left: 0 },
    };
  }

  handleTooltipClick = (event) => {
    const { top, left, height } = event.target.getBoundingClientRect();
    console.log("handleTooltipClick_1", this.state.tooltipVisible);
    this.setState((prevState) => ({
      tooltipVisible: !prevState.tooltipVisible,
      tooltipPosition: {
        top: top + height + 5 + window.scrollY,
        left: left - 230,
      },
    }));
  };

  render() {
    const { tripData, index, isAnyFilterApplied } = this.props;
    const {
      groupCode,
      startTime,
      from,
      to,
      seater,
      routeType,
      isContractExpired,
      supplyCost,
    } = tripData;
    const { tooltipVisible } = this.state;

    return (
      <>
        {tooltipVisible && (
          <div
            className="tooltipBox"
            style={{
              position: "absolute",
              top: this.state.tooltipPosition.top,
              left: this.state.tooltipPosition.left,
            }}
          >
            <div className="tooltipRow">
              <span>Contact Admin team for Assistance</span>
            </div>
          </div>
        )}

        <div
          className="fmlmPaymentSummaryTile"
          style={
            index === 0 && !isAnyFilterApplied ? { marginTop: "5px" } : null
          }
        >
          <div className="tripHeader">
            <span className="tripId">Trip ID: {groupCode}</span>
            <div className="tripDateAndTime">
              <span className="tripDate">
                {formatDate(startTime, "DD MMM YY")}
              </span>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/tile/dot.svg"
                alt="dot"
              />
              <span className="tripTime">
                {formatTime(startTime, "h:mm A")}
              </span>
            </div>
          </div>
          <div className="tripRoute">
            <span className="routeFromTo">
              <strong>{from}</strong>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/opd/tiles/blackarrowright.svg"
                alt="Arrow Icon"
                className="arrowIcon"
              />
              <strong>{to}</strong>
            </span>
          </div>
          <div className="tripDetails">
            <div>
              <span className="seater">{seater}</span>
              <span className="passengers">{routeType}</span>
            </div>
            {startTime > Date.now() ? (
              <div className="amountContainer">
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/time-outline.svg"
                  alt="clock icon"
                  className="clockIcon"
                />
                <span className="text tripNotStarted">Trip Not Started</span>
              </div>
            ) : isContractExpired ? (
              <div className="amountContainer">
                <span className="text contractInvalid">Contract Invalid</span>
                <img
                  src="https://d1flzashw70bti.cloudfront.net/original/images/optdash/informationicon.svg"
                  alt="information icon"
                  className="infoIcon"
                  onClick={this.handleTooltipClick}
                />
              </div>
            ) : (
              <div className="amountContainer">
                <span className="text">₹{supplyCost}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default FmlmPaymentSummaryTile;
